import { accessibilityColors } from '@edulastic/colors'

export const testStatusBackgroundColor = {
  draft: accessibilityColors.labels.grey.bg,
  published: accessibilityColors.labels.greyBluish.bg,
  rejected: accessibilityColors.labels.red.bg,
  inreview: accessibilityColors.labels.cyan.bg,
}

export const testStatusTextColor = {
  draft: accessibilityColors.labels.grey.text,
  published: accessibilityColors.labels.greyBluish.text,
  rejected: accessibilityColors.labels.red.text,
  inreview: accessibilityColors.labels.cyan.text,
}

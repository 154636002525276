import React, { useEffect, useState } from 'react'

const withAccessibility = (WrappedComponent) => {
  const WithAccessibility = (props) => {
    const { dangerouslySetInnerHTML } = props

    if (!dangerouslySetInnerHTML || !dangerouslySetInnerHTML.__html) {
      return <WrappedComponent {...props} />
    }

    const [updatedInnerHtml, setUpdatedInnerHtml] = useState('')

    useEffect(() => {
      const processImages = (html) => {
        // Use DOMParser to create a DOM object from the HTML string
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, 'text/html')

        // Select all img elements
        const images = doc.querySelectorAll('img')

        // Iterate over images and add alt attributes
        images.forEach((img) => {
          if (!img.getAttribute('alt')) {
            try {
              const src = img.getAttribute('src')
              const altText = src
                .substring(src.lastIndexOf('/') + 1)
                .split('.')[0]
              img.setAttribute('alt', altText)
            } catch (e) {
              console.error(e)
            }
          }
        })

        // Convert the modified DOM back to an HTML string
        const serializer = new XMLSerializer()
        const newHtml = serializer.serializeToString(doc.body)

        setUpdatedInnerHtml(newHtml)
      }

      processImages(dangerouslySetInnerHTML.__html)
    }, [dangerouslySetInnerHTML])

    return (
      <WrappedComponent
        {...props}
        dangerouslySetInnerHTML={{ __html: updatedInnerHtml }}
      />
    )
  }

  return WithAccessibility
}

export default withAccessibility

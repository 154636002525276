import { get as _get, groupBy, isEmpty } from 'lodash'
import { createSelector } from 'reselect'
import { roleuser, userPermissions } from '@edulastic/constants'
import {
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from '@edulastic/constants/const/roleType'
import { formatName } from '@edulastic/constants/reportUtils/common'
import { getSchoolsSelector as getDistrictSchoolsSelector } from '../../Schools/ducks'
import { getDefaultInterests } from '../../dataUtils'
import { getCurriculumsListSelector } from './dictionaries'
import {
  GCPS_DEMO_USER_IDS,
  GCPS_DISTRICT_IDS,
  QTI_DISTRICTS,
} from '../../../config'

export const etsDistrict = '5e42a351a1ee9000081f7cda'

export const stateSelector = (state) => state.user

export const getUserIdSelector = createSelector(stateSelector, (state) =>
  _get(state, 'user._id', '')
)

export const getUserSelector = createSelector(stateSelector, (state) => state)

export const getDefaultGradesSelector = createSelector(
  stateSelector,
  (state) => state.user.orgData.selectedGrades
)

export const getDefaultSubjectSelector = createSelector(
  stateSelector,
  (state) => state.user.orgData.selectedSubject || ''
)

export const getIsDistrictGroupUser = createSelector(stateSelector, (state) => {
  const districtGroup = state.user.orgData?.districtGroup
  return !!districtGroup?._id
})

export const getUserNameSelector = createSelector(stateSelector, (state) => {
  let name = ''
  if (state.user) {
    name = formatName([state.user.firstName, '', state.user.lastName], {
      lastNameFirst: false,
    })
  }
  return name || 'Anonymous'
})

export const getUserFullNameSelector = createSelector(
  stateSelector,
  (state) => {
    const { firstName = '', middleName = '', lastName = '' } = state.user
    return [firstName, middleName, lastName].filter((x) => !!x).join(' ')
  }
)

export const getUserEmailSelector = createSelector(
  stateSelector,
  (state) => state.user.email || ''
)

export const getOrgDataSelector = createSelector(
  stateSelector,
  (state) => state?.user?.orgData || {}
)

export const getCurrentGroup = createSelector(
  stateSelector,
  (state) => state.user && state.user.orgData && state.user.orgData.defaultClass
)

export const getGroupList = createSelector(
  stateSelector,
  (state) => state.user && state.user.orgData && state.user.orgData.classList
)

export const getUserRole = createSelector(
  stateSelector,
  (state) => state?.user?.role
)

export const isSuperAdminSelector = createSelector(
  stateSelector,
  (state) => state?.user?.features?.isSuperAdmin
)

export const getIsTutorMeEnabled = createSelector(
  stateSelector,
  (state) => state?.user?.features?.isTutorMeEnabled
)

export const getChildrens = createSelector(
  stateSelector,
  (state) => state?.user?.children
)

export const getUserIPZipCode = createSelector(
  stateSelector,
  (state) => state.user?.location?.zip || undefined
)

export const getUser = createSelector(stateSelector, (state) => state.user)

export const getUserThumbnail = createSelector(
  getUser,
  (state) => state.thumbnail
)

export const getIsPublisherDistrictSelector = createSelector(
  stateSelector,
  (state) =>
    state.user?.orgData?.districts?.find((obj) =>
      obj.districtPermissions.includes('publisher')
    ) || false
)

export const getCurrentTerm = createSelector(stateSelector, (state) =>
  _get(state, 'user.orgData.defaultTermId')
)

export const currentTermGroupsListSelector = createSelector(
  getGroupList,
  getCurrentTerm,
  (classList, currentTerm) =>
    classList.filter((item) => item.termId === currentTerm)
)

export const getCurrentActiveTerms = createSelector(stateSelector, (state) => {
  const terms = _get(state, 'user.orgData.terms', [])
  const currentTime = new Date().getTime()
  const currentTerms = terms.filter(
    (o) => o.startDate <= currentTime && o.endDate >= currentTime
  )
  return currentTerms
})

export const getCurrentActiveTermIds = createSelector(
  getCurrentActiveTerms,
  (state) => state.map((x) => x._id)
)

export const getAllInterestedCurriculumsSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'interestedCurriculums', [])
)

export const getInterestedCurriculumsSelector = createSelector(
  getAllInterestedCurriculumsSelector,
  (allInterestedCurriculums) => {
    // This is the heirarchy being followed in backend.
    const orgTypes = [
      'teacher',
      'school-admin',
      'institution',
      'district-admin',
      'district',
    ]
    const orgTypeWiseCurriculums = groupBy(
      allInterestedCurriculums,
      (intrstCurm) => {
        return intrstCurm.orgType
      }
    )
    for (const orgType of orgTypes) {
      const orgCurriculums = orgTypeWiseCurriculums[orgType]
      if (!isEmpty(orgCurriculums)) {
        return orgCurriculums
      }
    }
    return []
  }
)

export const getShowAllCurriculumsSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'showAllStandards', true)
)

export const getInterestedGradesSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'defaultGrades', [])
)

export const getInterestedSubjectsSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'defaultSubjects', [])
)

export const getPreviouslyUsedOrDefaultInterestsSelector = createSelector(
  getDefaultInterests,
  getInterestedSubjectsSelector,
  getInterestedGradesSelector,
  getInterestedCurriculumsSelector,
  (s) => getCurriculumsListSelector(s),
  (
    previousInterests,
    interestedSubjects,
    interestedGrades,
    interestedCurriculums,
    curriculums
  ) => {
    const _subject = Array.isArray(previousInterests?.subject)
      ? previousInterests?.subject[0]
      : previousInterests?.subject
    const subject = _subject || interestedSubjects[0] || ''

    const curriculum =
      curriculums?.find(
        (curr) =>
          curr._id === previousInterests.curriculumId &&
          curr.subject === subject
      ) || interestedCurriculums.find((cur) => cur.subject === subject)

    return {
      subject,
      grades: !isEmpty(previousInterests.grades)
        ? previousInterests.grades
        : interestedGrades || [],
      curriculum: {
        id: parseInt(curriculum?._id, 10) || '',
        curriculum: curriculum?.curriculum || curriculum?.name || '',
      },
    }
  }
)

export const getPearTokenSelector = createSelector(stateSelector, (state) =>
  _get(state, 'user.pearToken', undefined)
)

/**
 * this selector shouldn't be used for students
 * student can be part of multiple district
 * @type {OutputSelector<unknown, string, (Object) => string>}
 */
export const getUserOrgId = createSelector(stateSelector, (state) =>
  _get(state, 'user.currentDistrictId', _get(state, 'user.districtIds[0]'))
)
/**
 * this selector shouldn't be used for students
 * student can be part of multiple district
 */
export const getUserOrg = createSelector(
  stateSelector,
  getUserOrgId,
  (state, orgId) =>
    (state.user.orgData?.districts || []).find((d) => d.districtId === orgId)
)

/**
 * this selector shouldn't be used for students
 * student can be part of multiple district
 * @type {OutputSelector<unknown, string, (Object) => string>}
 */
export const getUserOrgName = createSelector(
  getUserOrg,
  (userOrg) => userOrg?.districtName
)

export const getUserFeatures = createSelector(stateSelector, (state) => {
  const features = _get(state, 'user.features')
  return features
})

export const getIsAiEvaulationDistrictSelector = createSelector(
  getUserFeatures,
  (features) => features?.isVideoQuizAndAIEnabled || false
)

export const enableSectionAdaptiveTestSelector = createSelector(
  getUserFeatures,
  (features) => features?.enableSectionAdaptiveTest || false
)

export const getIsTutorMeEnabledSelector = createSelector(
  getUserFeatures,
  (features) => features?.isTutorMeEnabled || false
)

export const getIsPearAppsDisabledSelector = createSelector(
  getUserFeatures,
  (features) => features?.disablePearApps || false
)

export const getDataWarehouseReports = () =>
  createSelector(getUserFeatures, (features) => features.dataWarehouseReports)

export const getUserOrgData = createSelector(stateSelector, (state) =>
  _get(state, 'user.orgData', {})
)

/**
 * this selector shouldn't be used for students
 * student can be part of multiple district
 */
export const getOrgSchools = createSelector(
  getUserOrgData,
  (orgData) => orgData.schools || []
)

export const getOrgGroupList = createSelector(
  getUserOrgData,
  (orgData) => orgData.classList || []
)

export const getCurrentSchool = createSelector(
  getUserOrgData,
  (orgData) => orgData.defaultSchool
)

export const getCurrentSchoolState = createSelector(
  getUserOrg,
  getOrgSchools,
  getCurrentSchool,
  (orgData, schools, schoolId) => {
    if (orgData?.districtStatus === 1) {
      return orgData?.districtState
    }
    const currentSchool =
      schools.find((sc) => sc._id === schoolId) || schools?.[0]
    return currentSchool?.location?.state
  }
)

export const isHomeSchoolSelector = createSelector(getUserOrg, (userOrg) => {
  return userOrg?.districtStatus === 2
})

export const currentDistrictInstitutionIds = createSelector(
  getOrgSchools,
  (schools) => schools.map((item) => item._id)
)

export const getOrgItemBanksSelector = createSelector(stateSelector, (state) =>
  _get(state, 'user.orgData.itemBanks', [])
)

export const isFreeAdminSelector = createSelector(
  getUserRole,
  getUserFeatures,
  (userRole, userFeatures) =>
    roleuser.DA_SA_ROLE_ARRAY.includes(userRole) && !userFeatures.premium
)

export const isUserLoggedInFirstTimeOnAddAccountSelector = createSelector(() =>
  sessionStorage.getItem('addAccountDetails')
)

export const isSAWithoutSchoolsSelector = createSelector(
  getUserRole,
  currentDistrictInstitutionIds,
  (userRole, institutionIds) =>
    userRole === roleuser.SCHOOL_ADMIN && !institutionIds.length
)

export const getUserSignupStatusSelector = createSelector(
  stateSelector,
  (state) => _get(state, 'signupStatus', '')
)

export const getIsGridEditEnabledSelector = createSelector(
  getOrgDataSelector,
  getUserOrgId,
  (state, currentDistrictId) =>
    state?.districts
      ?.find((district) => district.districtId === currentDistrictId)
      ?.districtPermissions?.includes('grid-edit')
)

export const getCollectionsSelector = createSelector(
  getOrgItemBanksSelector,
  (state) => state.filter((item) => item.status === 1)
)

export const shouldWatchCollectionUpdates = createSelector(
  getUserRole,
  (userRole) =>
    userRole === roleuser.TEACHER ||
    userRole === roleuser.DISTRICT_ADMIN ||
    userRole === roleuser.SCHOOL_ADMIN
)

export const getCustomCollectionsSelector = createSelector(
  getCollectionsSelector,
  (collections) =>
    collections.filter((item) => item.isCustom || item.accessLevel === 'write')
)

export const getWritableCollectionsSelector = createSelector(
  getCustomCollectionsSelector,
  getUserOrgId,
  (collections, districtId) =>
    collections.filter(
      (item) =>
        (item.districtId === districtId && item.accessLevel !== 'read') ||
        item.accessLevel === 'write'
    )
)

export const getRosterSourcedId = createSelector(
  getOrgDataSelector,
  getUserOrgId,
  (state, currentDistrictId) => {
    return state?.districts?.find(
      (district) => district?.districtId === currentDistrictId
    )?.rosterSourcedId
  }
)

export const convertCollectionsToBucketList = (collections) => {
  const flatttenBuckets = collections.flatMap((collection) =>
    collection.buckets.map((bucket) => ({
      ...bucket,
      _id: collection._id,
      bucketId: bucket._id,
      collectionStatus: collection.status,
      collectionName: collection.name,
      collectionDescription: collection.description,
      accessLevel: collection.accessLevel || '',
      districtId: collection.districtId,
      type: collection.type,
    }))
  )
  return flatttenBuckets || []
}

export const getItemBucketsSelector = createSelector(
  getCustomCollectionsSelector,
  (state) => {
    return convertCollectionsToBucketList(state)
  }
)

export const getItemBucketsForAllCollectionsSelector = createSelector(
  getCollectionsSelector,
  (state) => {
    return convertCollectionsToBucketList(state)
  }
)

export const getUserId = createSelector(getUser, (state) => _get(state, '_id'))

export const getIsPublisherAuthor = createSelector(
  getUserFeatures,
  (features) => _get(features, 'isPublisherAuthor', false)
)

export const getIsCurator = createSelector(getUserFeatures, (features) =>
  _get(features, 'isCurator', false)
)

export const isPublisherUserSelector = createSelector(
  getIsPublisherAuthor,
  getIsCurator,
  (isPublisherAuthor, isCurator) => isPublisherAuthor || isCurator
)

export const isCuratorRoleSelector = createSelector(
  getUserRole,
  (role) => role === roleuser.EDULASTIC_CURATOR
)

export const getCollectionsToAddContent = createSelector(
  getItemBucketsSelector,
  getUserRole,
  isPublisherUserSelector,
  getUserOrgId,
  (itemBanks, userRole, isAuthorPublisher, userDistrictId) => {
    if (isAuthorPublisher || userRole !== roleuser.DISTRICT_ADMIN) {
      return itemBanks.filter((c) => c.accessLevel === 'write')
    }
    return itemBanks.filter(
      (c) =>
        (c.districtId === userDistrictId && c.accessLevel !== 'read') ||
        c.accessLevel === 'write'
    )
  }
)

export const showItemStatusSelector = createSelector(
  isPublisherUserSelector,
  getUserRole,
  (isPublisher, role) => isPublisher || role === roleuser.EDULASTIC_CURATOR
)

export const getUserSchoolsListSelector = createSelector(
  getOrgDataSelector,
  (state) => state.schools
)

export const getSchoolsByUserRoleSelector = createSelector(
  getUserRole,
  getUserSchoolsListSelector,
  getDistrictSchoolsSelector,
  (role, userSchools, districtSchools) =>
    role === 'teacher' ? userSchools : districtSchools
)

export const isOrganizationDistrictUserSelector = createSelector(
  getOrgDataSelector,
  (state) => state?.districts?.[0]?.districtPermissions.includes('publisher')
)

export const isOrganizationDistrictSelector = createSelector(
  getUser,
  (state) => {
    if (
      state.role === roleuser.DISTRICT_ADMIN &&
      state.orgData?.districts?.[0]?.districtPermissions.includes('publisher')
    ) {
      return true
    }
    return false
  }
)

export const getManageTabLabelSelector = createSelector(
  getUser,
  isOrganizationDistrictSelector,
  (state, isOrganization) => {
    if (isOrganization) return 'Organization'
    if (state.role === roleuser.DISTRICT_ADMIN) return 'Manage District'
    return 'Manage School'
  }
)

export const getSaSchoolsSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'schools', [])
)

export const getSaSchoolsSortedSelector = createSelector(
  getSaSchoolsSelector,
  (schools) => schools.sort((a, b) => a.name.localeCompare(b.name))
)

export const getOrgPoliciesSelector = createSelector(
  getOrgDataSelector,
  (state) => _get(state, 'policies', {})
)

export const getInstitutionPoliciesSelector = createSelector(
  getSaSchoolsSelector,
  getOrgPoliciesSelector,
  (schools, policies) =>
    schools.map((s) => {
      let schoolPolicy = policies?.institutions?.find(
        (i) => i.institutionId === s._id
      )
      if (!schoolPolicy) {
        schoolPolicy = policies?.district
      }
      return {
        ...schoolPolicy,
        institutionId: s._id,
        institutionName: s.name,
        institution: s,
      }
    })
)

export const getGoogleAllowedInstitionPoliciesSelector = createSelector(
  getInstitutionPoliciesSelector,
  (state) => state.filter((s) => !!s.allowGoogleClassroom)
)

export const getCanvasAllowedInstitutionPoliciesSelector = createSelector(
  getInstitutionPoliciesSelector,
  (state) => state.filter((s) => !!s.allowCanvas)
)

export const getCleverLibraryUserSelector = createSelector(
  getUser,
  getOrgDataSelector,
  (user, orgData) => !orgData.isCleverDistrict && user.cleverId
)

export const getCleverSyncEnabledInstitutionPoliciesSelector = createSelector(
  getCleverLibraryUserSelector,
  getInstitutionPoliciesSelector,
  (user, policies) =>
    user
      ? policies.filter(
          (p) =>
            !(
              p.allowGoogleClassroom ||
              p.allowCanvas ||
              p.allowSchoology ||
              p.allowClassLink
            )
        )
      : []
)

export const getAccountSwitchDetails = createSelector(
  stateSelector,
  (state) => {
    const {
      user: { personId },
      otherAccounts: { accounts },
    } = state
    const details = { personId, otherAccounts: accounts || [] }
    details.switchAccounts = details.otherAccounts.flatMap((acc) =>
      acc.districts.length
        ? acc.districts.map((district) => ({
            ...acc,
            district,
            institutions: acc.institutions.filter(
              (inst) => inst.districtId === district._id
            ),
          }))
        : {
            ...acc,
            district: {},
          }
    )
    return details
  }
)

export const getIsPowerPremiumAccount = createSelector(
  getUser,
  (state) => state.isPowerTeacher && state.features.premium
)

export const isPremiumUserSelector = createSelector(getUser, (userData) =>
  _get(userData, ['features', 'premium'], false)
)

export const isGcpsDistrictSelector = createSelector(
  getUserOrgId,
  getUserIdSelector,
  (state, excludeDemoUsers = false) => excludeDemoUsers,
  (districtId, userId, excludeDemoUsers) => {
    if (excludeDemoUsers && GCPS_DISTRICT_IDS.includes(districtId)) {
      return !GCPS_DEMO_USER_IDS.includes(userId)
    }
    return GCPS_DISTRICT_IDS.includes(districtId)
  }
)

export const isQTIDistrictSelector = createSelector(getUser, (userData) =>
  QTI_DISTRICTS?.some((qtiDistrict) =>
    _get(userData, ['districtIds'], []).includes(qtiDistrict)
  )
)

export const isVideoQuizAndAIEnabledSelector = createSelector(
  getUser,
  (userData) => _get(userData, ['features', 'isVideoQuizAndAIEnabled'], false)
)

export const vqUsageCountSelector = createSelector(getUser, (userData) =>
  _get(userData, ['features', 'vqUsageCount'], 0)
)

export const vqQuotaForDistrictSelector = createSelector(getUser, (userData) =>
  _get(userData, ['features', 'vqQuotaForDistrict'], 0)
)

/**
 * Shows video quiz counter
 * When user don't have subscription and user is premium and vqQuota is greater than 0
 */
export const showVQCountSelector = createSelector(
  vqUsageCountSelector,
  vqQuotaForDistrictSelector,
  isPremiumUserSelector,
  isVideoQuizAndAIEnabledSelector,
  (
    vqUsageCount,
    vqQuotaForDistrict,
    isPremiumUser,
    isVideoQuizAndAiEnabledUser
  ) => !isVideoQuizAndAiEnabledUser && isPremiumUser && vqQuotaForDistrict > 0
)

/**
 * Shows vq add-on popup
 * Shows premium add-on Tag
 * We don't show popup when vqQuota and usageCount is equal.
 * so that user can assign existing Tests
 */
export const isRedirectToVQAddOnSelector = createSelector(
  isPremiumUserSelector,
  vqQuotaForDistrictSelector,
  vqUsageCountSelector,
  isVideoQuizAndAIEnabledSelector,
  (isPremiumUser, vqQuotaForDistrict, vqUsageCount, isVideoQuizAndAIEnabled) =>
    !isVideoQuizAndAIEnabled && (!isPremiumUser || vqQuotaForDistrict === 0)
)

/**
 * Redirects user to subscription page when create video quiz is not allowed
 */
export const allowedToCreateVideoQuizSelector = createSelector(
  isVideoQuizAndAIEnabledSelector,
  vqQuotaForDistrictSelector,
  vqUsageCountSelector,
  isPremiumUserSelector,
  (isVideoQuizAndAIEnabled, vqQuotaForDistrict, vqUsageCount, isPremiumUser) =>
    isVideoQuizAndAIEnabled ||
    (isPremiumUser &&
      (vqQuotaForDistrict === -1 || vqQuotaForDistrict > vqUsageCount))
)

export const getInterestedCurriculumsByOrgType = createSelector(
  getInterestedCurriculumsSelector,
  getUserRole,
  (interestedCurriculums, role) => {
    const byOrgType = groupBy(interestedCurriculums, 'orgType')
    const { ORG_TYPE } = roleuser
    if (role === roleuser.TEACHER) {
      return byOrgType[ORG_TYPE.TEACHER] || []
    }
    if (role === roleuser.SCHOOL_ADMIN) {
      return byOrgType[ORG_TYPE.SCHOOL_ADMIN] || []
    }
    if (role === roleuser.DISTRICT_ADMIN) {
      return byOrgType[ORG_TYPE.DISTRICT_ADMIN] || []
    }
    return interestedCurriculums
  }
)

export const isDistrictUserSelector = createSelector(
  getOrgDataSelector,
  (state) =>
    state?.districts?.[0]?.districtPermissions &&
    state?.districts?.[0]?.districtPermissions.filter(
      (item) => item !== 'grid-edit'
    ).length === 0
)

export const isCoTeacherSelector = createSelector(
  isPublisherUserSelector,
  getUserRole,
  (isPublisher, role) =>
    isPublisher ||
    role === roleuser.DISTRICT_ADMIN ||
    role === roleuser.SCHOOL_ADMIN ||
    role === roleuser.TEACHER
)

export const getUserPreferredLanguage = createSelector(
  getUserSelector,
  (state) => state?.user?.preferredLanguage
)

export const currentUserIdSelector = createSelector(
  getUserSelector,
  (state) => state?.user?._id
)

export const allowedToSelectMultiLanguageInTest = createSelector(
  getUserFeatures,
  getIsCurator,
  (userFeatures, isCurator) => {
    return _get(userFeatures, 'allowMultiLanguageAuthoring', false) || isCurator
  }
)

export const isEtsDistrictSelector = createSelector(
  getUserOrgId,
  (districtId) => districtId === etsDistrict
)

export const allowReferenceMaterialSelector = createSelector(
  getUserFeatures,
  getUserRole,
  (features, role) =>
    role === roleuser.STUDENT || _get(features, 'allowReferenceMaterial', false)
)

export const isDataOpsUser = createSelector(getUserFeatures, (features) =>
  _get(features, 'isDataOpsUser', false)
)

export const isDataOpsOnlyUser = createSelector(getUserFeatures, (features) =>
  _get(features, 'isDataOpsOnlyUser', false)
)

export const isDesmosCalculatorEnabledSelector = createSelector(
  getUserFeatures,
  (features) => _get(features, 'isDesmosCalculatorEnabled', false)
)

export const canAccessPublicContentSelector = createSelector(
  getUserFeatures,
  (features) => _get(features, 'canAccessPublicContent', true)
)

export const isDistrictAdminSelector = createSelector(
  getUserRole,
  (userRole) => DISTRICT_ADMIN === userRole
)

export const isSchoolAdminSelector = createSelector(
  getUserRole,
  (userRole) => SCHOOL_ADMIN === userRole
)

export const isAdminSelector = createSelector(
  isDistrictAdminSelector,
  isSchoolAdminSelector,
  (isDistrictAdmin, isSchoolAdmin) => isDistrictAdmin || isSchoolAdmin
)

export const getDistrictPoliciesSelector = createSelector(
  getOrgDataSelector,
  (state) => state.districtPolicies || []
)

export const getManualEnrollmentAllowedSelector = createSelector(
  getOrgDataSelector,
  (state) => state?.policies?.district?.manualEnrollmentAllowed ?? true
)

export const getUserDistrict = createSelector(
  [getOrgDataSelector, getUserOrgId],
  (orgData, userOrgId) =>
    orgData.districts?.find((district) => district.districtId === userOrgId) ||
    {}
)

export const getIsCanvasSynNotCompleted = createSelector(
  getUserDistrict,
  (district) => district?.syncStatus !== 8 && !district?.rosterSourcedId
)

export const getIsDistrictSynced = createSelector(
  getUserDistrict,
  (district) => district.cleverId || district.atlasId
)

export const isPermissionSuperAdmin = createSelector(getUserSelector, (state) =>
  // NOTE: Some users may not have `permissions` property. ex. district-group-admin
  state.user?.permissions?.includes(userPermissions.SUPER_ADMIN)
)

export const isUserDistrictSuperAdminSelector = createSelector(
  isPermissionSuperAdmin,
  isDistrictAdminSelector,
  (isSuperAdmin, isDistrictAdmin) => isSuperAdmin && isDistrictAdmin
)

export const getClassResponseDataSelector = (state) =>
  _get(state, 'classResponse.data', {})

export const getCurriculumDropdownOptions = createSelector(
  getAllInterestedCurriculumsSelector,
  getClassResponseDataSelector,
  (interestedCurriculums, classResponseData) => {
    const reportStandards = _get(classResponseData, 'reportStandards', [])
    const curriculumIds = new Set(
      reportStandards.map((item) => item.curriculumId)
    )

    return interestedCurriculums
      .filter((curriculum) => curriculumIds.has(curriculum._id))
      .map(({ _id, name }) => ({ key: `${_id}`, title: name }))
  }
)

export const isAddItemQuickLinkEnabledSelector = createSelector(
  getUser,
  (userData) => userData?.features?.isAddItemQuickLinksEnabled || false
)

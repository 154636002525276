import React from 'react'
import { CustomImage } from './CustomImage'

import Path2743 from '../assets/keyboardButtons/Path2743.svg'
import Path2744 from '../assets/keyboardButtons/Path2744.svg'
import Path3357 from '../assets/keyboardButtons/Path3357.svg'
import Path3358 from '../assets/keyboardButtons/Path3358.svg'
import Path3359 from '../assets/keyboardButtons/Path3359.svg'
import Path3360 from '../assets/keyboardButtons/Path3360.svg'
import Path3362 from '../assets/keyboardButtons/Path3362.svg'
import Path3526 from '../assets/keyboardButtons/Path3526.svg'
import Path3529 from '../assets/keyboardButtons/Path3529.svg'
import Path3521 from '../assets/keyboardButtons/Path3521.svg'
import Path3522 from '../assets/keyboardButtons/Path3522.svg'
import Path3523 from '../assets/keyboardButtons/Path3523.svg'
import Path3524 from '../assets/keyboardButtons/Path3524.svg'
import Path3525 from '../assets/keyboardButtons/Path3525.svg'
import Path3501 from '../assets/keyboardButtons/Path3501.svg'
import Path3502 from '../assets/keyboardButtons/Path3502.svg'
import Path3503 from '../assets/keyboardButtons/Path3503.svg'
import Path3504 from '../assets/keyboardButtons/Path3504.svg'
import Path3505 from '../assets/keyboardButtons/Path3505.svg'
import Group993 from '../assets/keyboardButtons/993.svg'
import Group999 from '../assets/keyboardButtons/999.svg'
import Group2735 from '../assets/operators/2735.svg'
import Group2737 from '../assets/operators/2737.svg'
import Group2734 from '../assets/operators/2734.svg'
import Group2788 from '../assets/operators/2788.svg'
import Group2736 from '../assets/operators/2736.svg'
import Group3341 from '../assets/operators/3341.svg'
import Group3527 from '../assets/operators/3527.svg'
import Group3528 from '../assets/operators/3528.svg'
import Group2475 from '../assets/keyboardButtons/2475.svg'
import Group6199 from '../assets/keyboardButtons/6199.svg'
import Group4065 from '../assets/keyboardButtons/4065.svg'
import Group2740 from '../assets/keyboardButtons/2740.svg'
import Group2715 from '../assets/keyboardButtons/2715.svg'

import Group2480 from '../assets/keyboardButtons/2480.svg'
import Group2751 from '../assets/keyboardButtons/2751.svg'
import Group1054 from '../assets/keyboardButtons/1054.svg'
import Group3167 from '../assets/keyboardButtons/3167.svg'
import Group2745 from '../assets/keyboardButtons/2745.svg'
import Group2752 from '../assets/keyboardButtons/2752.svg'
import Group3168 from '../assets/keyboardButtons/3168.svg'
import Group8360 from '../assets/keyboardButtons/8360.svg'
import Group8380 from '../assets/keyboardButtons/8380.svg'
import Group2267 from '../assets/keyboardButtons/2267.svg'
import Group3180 from '../assets/keyboardButtons/3180.svg'
import Group3169 from '../assets/keyboardButtons/3169.svg'
import Group3177 from '../assets/keyboardButtons/3177.svg'
import Group3170 from '../assets/keyboardButtons/3170.svg'
import Group3172 from '../assets/keyboardButtons/3172.svg'
import Group3171 from '../assets/keyboardButtons/3171.svg'
import Group3163 from '../assets/keyboardButtons/3163.svg'
import Group6101 from '../assets/keyboardButtons/6101.svg'
import Group3181 from '../assets/keyboardButtons/3181.svg'
import Group1030 from '../assets/keyboardButtons/1030.svg'
import Group1031 from '../assets/keyboardButtons/1031.svg'
import Group1032 from '../assets/keyboardButtons/1032.svg'
import Group1033 from '../assets/keyboardButtons/1033.svg'
import Group1043 from '../assets/keyboardButtons/1043.svg'
import Group943 from '../assets/keyboardButtons/943.svg'

import Path3512 from '../assets/units/Path3512.svg'
import Path3530 from '../assets/units/Path3530.svg'
import Path3531 from '../assets/units/Path3531.svg'
import Path3532 from '../assets/units/Path3532.svg'
import Path3534 from '../assets/units/Path3534.svg'
import Path3535 from '../assets/units/Path3535.svg'
import Path3536 from '../assets/units/Path3536.svg'
import Path3537 from '../assets/units/Path3537.svg'
import Path3538 from '../assets/units/Path3538.svg'
import Path3539 from '../assets/units/Path3539.svg'
import Path3540 from '../assets/units/Path3540.svg'
import Path3541 from '../assets/units/Path3541.svg'
import Path3548 from '../assets/units/Path3548.svg'
import Path3549 from '../assets/units/Path3549.svg'
import Group3378 from '../assets/units/Group3378.svg'
import Group8393 from '../assets/units/Group8393.svg'
import Group8394 from '../assets/units/Group8394.svg'
import Group8395 from '../assets/units/Group8395.svg'
import Group8396 from '../assets/units/Group8396.svg'
import Group8397 from '../assets/units/Group8397.svg'
import Group8398 from '../assets/units/Group8398.svg'
import Group8557 from '../assets/units/Group8557.svg'
import Group8558 from '../assets/units/Group8558.svg'
import Group8559 from '../assets/units/Group8559.svg'
import Group8860 from '../assets/units/Group8860.svg'
import Group8861 from '../assets/units/Group8861.svg'
import Group8862 from '../assets/units/Group8862.svg'
import Group8863 from '../assets/units/Group8863.svg'
import Group8864 from '../assets/units/Group8864.svg'
import Group8865 from '../assets/units/Group8865.svg'
import Group8866 from '../assets/units/Group8866.svg'
import Group8867 from '../assets/units/Group8867.svg'
import Group8868 from '../assets/units/Group8868.svg'
import Group8869 from '../assets/units/Group8869.svg'
import Group8870 from '../assets/units/Group8870.svg'
import Group8871 from '../assets/units/Group8871.svg'
import Group8872 from '../assets/units/Group8872.svg'
import Group8873 from '../assets/units/Group8873.svg'
import Group8874 from '../assets/units/Group8874.svg'
import Group8876 from '../assets/units/Group8876.svg'
import Group8877 from '../assets/units/Group8877.svg'
import Group8878 from '../assets/units/Group8878.svg'
import GroupAcre from '../assets/units/acre.svg'
import GroupBytes from '../assets/units/bytes.svg'
import GroupMegabyte from '../assets/units/mb.svg'
import GroupGigabit from '../assets/units/gb.svg'
import GroupMol from '../assets/units/mol.svg'
import GroupGramMol from '../assets/units/g-mol.svg'
import GroupGramLiter from '../assets/units/g-ml.svg'
import Sqmi from '../assets/units/sqmi.svg'

import Group8879 from '../assets/arrows/Group8879.svg'
import Group8880 from '../assets/arrows/Group8880.svg'
import Group8881 from '../assets/arrows/Group8881.svg'
import Group8882 from '../assets/arrows/Group8882.svg'
import Group8883 from '../assets/arrows/Group8883.svg'
import Group8854 from '../assets/arrows/Group8854.svg'
import Group8885 from '../assets/arrows/Group8885.svg'
import Group8886 from '../assets/arrows/Group8886.svg'
import Group8887 from '../assets/arrows/Group8887.svg'
import Group8888 from '../assets/arrows/Group8888.svg'
import Group8889 from '../assets/arrows/Group8889.svg'
import Group8890 from '../assets/arrows/Group8890.svg'
import Group8891 from '../assets/arrows/Group8891.svg'
import Group8892 from '../assets/arrows/Group8892.svg'
import Group8893 from '../assets/arrows/Group8893.svg'
import Group8894 from '../assets/arrows/Group8894.svg'
import Group8895 from '../assets/arrows/Group8895.svg'
import Group8896 from '../assets/arrows/Group8896.svg'

import GreaterThan from '../assets/keyboardButtons/gt.svg'
import LessThan from '../assets/keyboardButtons/lt.svg'
import sqrtIcon from '../assets/keyboardButtons/sqrt.svg'
import fractionIcon from '../assets/keyboardButtons/fraction.svg'
import Group944 from '../assets/keyboardButtons/944.svg'
import dot from '../assets/keyboardButtons/dot.svg'
import Group941 from '../assets/keyboardButtons/941.svg'
import Group944v2 from '../assets/keyboardButtons/944-2.svg'
import Group942 from '../assets/keyboardButtons/942.svg'
import log from '../assets/keyboardButtons/log.svg'
import Group9295 from '../assets/keyboardButtons/9295.svg'
import Group9296 from '../assets/keyboardButtons/9296.svg'
import Group9297 from '../assets/keyboardButtons/9297.svg'
import Group9298 from '../assets/keyboardButtons/9298.svg'

import decimal from '../assets/numberpad/decimal.svg'
import equal from '../assets/numberpad/equal.svg'
import number1 from '../assets/numberpad/number1.svg'
import number2 from '../assets/numberpad/number2.svg'
import number3 from '../assets/numberpad/number3.svg'
import number4 from '../assets/numberpad/number4.svg'
import number5 from '../assets/numberpad/number5.svg'
import number6 from '../assets/numberpad/number6.svg'
import number7 from '../assets/numberpad/number7.svg'
import number8 from '../assets/numberpad/number8.svg'
import number9 from '../assets/numberpad/number9.svg'
import number0 from '../assets/numberpad/number0.svg'

import Path3391 from '../assets/greek/Path3391.svg'
import Path3399 from '../assets/greek/Path3399.svg'
import Path3405 from '../assets/greek/Path3405.svg'
import Path3411 from '../assets/greek/Path3411.svg'
import Path3417 from '../assets/greek/Path3417.svg'
import Path3423 from '../assets/greek/Path3423.svg'
import Path3429 from '../assets/greek/Path3429.svg'
import Path3436 from '../assets/greek/Path3436.svg'
import Path3444 from '../assets/greek/Path3444.svg'
import Path3392 from '../assets/greek/Path3392.svg'
import Path3400 from '../assets/greek/Path3400.svg'
import Path3406 from '../assets/greek/Path3406.svg'
import Path3412 from '../assets/greek/Path3412.svg'
import Path3418 from '../assets/greek/Path3418.svg'
import Path3424 from '../assets/greek/Path3424.svg'
import Path3430 from '../assets/greek/Path3430.svg'
import Path3437 from '../assets/greek/Path3437.svg'
import Path3394 from '../assets/greek/Path3394.svg'
import Path3395 from '../assets/greek/Path3395.svg'
import Path3401 from '../assets/greek/Path3401.svg'
import Path3407 from '../assets/greek/Path3407.svg'
import Path3413 from '../assets/greek/Path3413.svg'
import Path3419 from '../assets/greek/Path3419.svg'
import Path3425 from '../assets/greek/Path3425.svg'
import Path3432 from '../assets/greek/Path3432.svg'
import Path3431 from '../assets/greek/Path3431.svg'
import Path3438 from '../assets/greek/Path3438.svg'
import Path3396 from '../assets/greek/Path3396.svg'
import Path3402 from '../assets/greek/Path3402.svg'
import Path3408 from '../assets/greek/Path3408.svg'
import Path3414 from '../assets/greek/Path3414.svg'
import Path3420 from '../assets/greek/Path3420.svg'
import Path3426 from '../assets/greek/Path3426.svg'
import Path3433 from '../assets/greek/Path3433.svg'
import Path3441 from '../assets/greek/Path3441.svg'
import Path3439 from '../assets/greek/Path3439.svg'
import Path3397 from '../assets/greek/Path3397.svg'
import Path3403 from '../assets/greek/Path3403.svg'
import Path3409 from '../assets/greek/Path3409.svg'
import Path3415 from '../assets/greek/Path3415.svg'
import Path3421 from '../assets/greek/Path3421.svg'
import Path3427 from '../assets/greek/Path3427.svg'
import Path3434 from '../assets/greek/Path3434.svg'
import Path3442 from '../assets/greek/Path3442.svg'
import Path3454 from '../assets/greek/Path3454.svg'
import Path3455 from '../assets/greek/Path3455.svg'
import Path3456 from '../assets/greek/Path3456.svg'
import Path3457 from '../assets/greek/Path3457.svg'
import Path3458 from '../assets/greek/Path3458.svg'
import Path3459 from '../assets/greek/Path3459.svg'
import Path3460 from '../assets/greek/Path3460.svg'
import Path3461 from '../assets/greek/Path3461.svg'

import Group1002 from '../assets/matrix/1002.svg'
import Group1003 from '../assets/matrix/1003.svg'
import Group1004 from '../assets/matrix/1004.svg'
import Group1008 from '../assets/matrix/1008.svg'
import Group1009 from '../assets/matrix/1009.svg'
import Group1005 from '../assets/matrix/1005.svg'
import Group1015 from '../assets/matrix/1015.svg'
import Group1006 from '../assets/matrix/1006.svg'
import Group1007 from '../assets/matrix/1007.svg'
import Group1016 from '../assets/matrix/1016.svg'
import Group1017 from '../assets/matrix/1017.svg'
import Group1018 from '../assets/matrix/1018.svg'
import Group1019 from '../assets/matrix/1019.svg'
import Group1020 from '../assets/matrix/1020.svg'
import Group1021 from '../assets/matrix/1021.svg'

import Group1022 from '../assets/decorations/1022.svg'
import Group6104 from '../assets/decorations/6104.svg'
import Group6102 from '../assets/decorations/6102.svg'
import Group6103 from '../assets/decorations/6103.svg'
import Group1053 from '../assets/decorations/1053.svg'
import Group1055 from '../assets/decorations/1055.svg'
import Group1052 from '../assets/decorations/1052.svg'
import Group1023 from '../assets/decorations/1023.svg'
import Group1025 from '../assets/decorations/1025.svg'
import Group1045 from '../assets/decorations/1045.svg'

import Path3475 from '../assets/sets/Path3475.svg'
import Path3476 from '../assets/sets/Path3476.svg'
import Path3477 from '../assets/sets/Path3477.svg'
import Path3478 from '../assets/sets/Path3478.svg'
import Path3479 from '../assets/sets/Path3479.svg'
import Path3367 from '../assets/sets/Path3367.svg'
import Path2256 from '../assets/sets/Path2256.svg'
import Path2253 from '../assets/sets/Path2253.svg'
import Path2263 from '../assets/sets/Path2263.svg'
import Path2261 from '../assets/sets/Path2261.svg'
import Path2266 from '../assets/sets/Path2266.svg'
import Path2262 from '../assets/sets/Path2262.svg'
import Path2252 from '../assets/sets/Path2252.svg'
import Path2254 from '../assets/sets/Path2254.svg'
import Path2792 from '../assets/sets/Path2792.svg'

import Group6105 from '../assets/calculus/6105.svg'
import Group6106 from '../assets/calculus/6106.svg'
import Group1029 from '../assets/calculus/1029.svg'
import Group1058 from '../assets/calculus/1058.svg'
import Group1012 from '../assets/calculus/1012.svg'
import Group1013 from '../assets/calculus/1013.svg'
import Group1014 from '../assets/calculus/1014.svg'
import Group8507 from '../assets/calculus/8507.svg'
import Group3497 from '../assets/calculus/3497.svg'
import Group8819 from '../assets/calculus/8819.svg'
import Group8820 from '../assets/calculus/8820.svg'
import Group8821 from '../assets/calculus/8821.svg'
import Group8822 from '../assets/calculus/8822.svg'
import Group3521 from '../assets/calculus/3521.svg'
import Group8823 from '../assets/calculus/8823.svg'
import Group8824 from '../assets/calculus/8824.svg'
import Group8825 from '../assets/calculus/8825.svg'
import Group8826 from '../assets/calculus/8826.svg'
import Group8827 from '../assets/calculus/8827.svg'
import Group8828 from '../assets/calculus/8828.svg'
import Group8829 from '../assets/calculus/8829.svg'

import Chem320 from '../assets/chemistry/chem320.svg'
import Chem420 from '../assets/chemistry/chem420.svg'
import Chem520 from '../assets/chemistry/chem520.svg'
import Chem620 from '../assets/chemistry/chem620.svg'
import Chem720 from '../assets/chemistry/chem720.svg'
import Chem820 from '../assets/chemistry/chem820.svg'

// Trigonometry
import Trig2479 from '../assets/trigonometry/2479.svg' // sine
import Trig2481 from '../assets/trigonometry/2481.svg' // cos
import Trig3484 from '../assets/trigonometry/3484.svg' // sec
import Trig3485 from '../assets/trigonometry/3485.svg' // csc
import Trig2482 from '../assets/trigonometry/2482.svg' // tan
import Trig2483 from '../assets/trigonometry/2483.svg' // cot
import Trig2251 from '../assets/trigonometry/2251.svg' // arcsin
import Trig2247 from '../assets/trigonometry/2247.svg' // arccos
import Trig2249 from '../assets/trigonometry/2249.svg' // arcsec
import Trig2245 from '../assets/trigonometry/2245.svg' // arccosec
import Trig2243 from '../assets/trigonometry/2243.svg' // arctan
import Trig2248 from '../assets/trigonometry/2248.svg' // arccot
import Trig8924 from '../assets/trigonometry/8924.svg' // sinh
import Trig8925 from '../assets/trigonometry/8925.svg' // cosh
import Trig8923 from '../assets/trigonometry/8923.svg' // sech
import Trig8922 from '../assets/trigonometry/8922.svg' // csch
import Trig8921 from '../assets/trigonometry/8921.svg' // tanh
import Trig8920 from '../assets/trigonometry/8920.svg' // coth
import Trig8939 from '../assets/trigonometry/8939.svg' // inverse_sinh
import Trig8940 from '../assets/trigonometry/8940.svg' // inverse_cosh
import Trig8941 from '../assets/trigonometry/8941.svg' // inverse_sech
import Trig8942 from '../assets/trigonometry/8942.svg' // inverse_csch
import Trig8943 from '../assets/trigonometry/8943.svg' // inverse_tanh
import Trig8944 from '../assets/trigonometry/8944.svg' // inverse_coth

export const one = {
  value: '1',
  handler: '1',
  command: 'write',
  label: <CustomImage src={number1} alt="Number 1" width={6} height={14} />,
  dataCy: 'no1',
}
export const two = {
  value: '2',
  handler: '2',
  command: 'write',
  label: <CustomImage src={number2} alt="Number 2" width={8} height={14} />,
  dataCy: 'no2',
}
export const three = {
  value: '3',
  handler: '3',
  command: 'write',
  label: <CustomImage src={number3} alt="Number 3" width={8} height={14} />,
  dataCy: 'no3',
}
export const four = {
  value: '4',
  handler: '4',
  command: 'write',
  label: <CustomImage src={number4} alt="Number 4" width={10} height={15} />,
  dataCy: 'no4',
}
export const five = {
  value: '5',
  handler: '5',
  command: 'write',
  label: <CustomImage src={number5} alt="Number 5" width={8} height={14} />,
  dataCy: 'no5',
}
export const six = {
  value: '6',
  handler: '6',
  command: 'write',
  label: <CustomImage src={number6} alt="Number 6" width={8} height={14} />,
  dataCy: 'no6',
}
export const seven = {
  value: '7',
  handler: '7',
  command: 'write',
  label: <CustomImage src={number7} alt="Number 7" width={8} height={14} />,
  dataCy: 'no7',
}
export const eight = {
  value: '8',
  handler: '8',
  command: 'write',
  label: <CustomImage src={number8} alt="Number 8" width={8} height={14} />,
  dataCy: 'no8',
}
export const nine = {
  value: '9',
  handler: '9',
  command: 'write',
  label: <CustomImage src={number9} alt="Number 9" width={8} height={14} />,
  dataCy: 'no9',
}
export const zero = {
  value: '0',
  handler: '0',
  command: 'write',
  label: <CustomImage src={number0} alt="Number 0" width={8} height={14} />,
  dataCy: 'no0',
}
export const dotDecimal = {
  value: '.',
  handler: '.',
  label: (
    <CustomImage
      src={decimal}
      alt="Dot decimal symbol"
      width={35}
      height={25}
    />
  ),
  dataCy: 'dotDecimal',
}
export const equalSymbol = {
  value: '=',
  handler: '=',
  label: <CustomImage src={equal} alt="Equal symbol" width={12} height={8} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'equalSymbol',
}

export const notEqual = {
  handler: '\\ne',
  label: (
    <CustomImage
      src={Group3527}
      alt="Not equal symbol"
      width={12}
      height={32}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'notEqual',
}

export const plusButton = {
  handler: '+',
  label: (
    <CustomImage src={Group2735} alt="Plus symbol" width={14} height={14} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'plusButton',
}

export const minusButton = {
  handler: '-',
  label: (
    <CustomImage src={Group2737} alt="Minus symbol" width={12} height={8} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'minusButton',
}

export const asterisk = {
  handler: '*',
  label: (
    <CustomImage src={Group2734} alt="Asterisk symbol" width={14} height={14} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'asterisk',
}

export const divide = {
  handler: '\\div',
  label: (
    <CustomImage src={Group2788} alt="Divide symbol" width={12} height={12} />
  ),
  command: 'cmd',
  types: ['all'],
  dataCy: 'divide',
}

export const multiplication = {
  handler: '\\times',
  label: (
    <CustomImage
      src={Group2736}
      alt="Multiplication symbol"
      width={18}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'multiplication',
}

export const fraction = {
  handler: '/',
  labelcy: 'divide',
  value: 'divide',
  label: (
    <CustomImage
      src={fractionIcon}
      alt="Fraction symbol"
      width={25}
      height={40}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'fraction',
}

export const slashDivide = {
  handler: '/',
  labelcy: 'divide',
  value: 'divide',
  label: (
    <CustomImage
      src={Group3341}
      alt="Slash divide symbol"
      width={10}
      height={20}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'slashDivide',
}

export const parentheses = {
  handler: '(',
  labelcy: '(',
  label: <CustomImage src={Group944} alt="Parentheses symbol" />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'parentheses',
}

export const parentheses_start = {
  handler: '(',
  label: '(',
  types: ['all'],
  command: 'write',
  dataCy: 'parentheses_start',
}

export const parentheses_end = {
  handler: ')',
  label: ')',
  types: ['all'],
  command: 'write',
  dataCy: 'parentheses_end',
}

export const slash = {
  handler: '\\text{/}',
  labelcy: 'slash',
  label: (
    <CustomImage src={Group3341} alt="Slash symbol" width={10} height={20} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'slash',
}

export const timesDot = {
  handler: '\\cdot',
  label: <CustomImage src={dot} alt="Times dot symbol" width={6} height={6} />,
  types: ['all'],
  command: 'write',
  dataCy: 'timesDot',
}

export const brackets = {
  handler: '[',
  label: <CustomImage src={Group944v2} alt="Square brackets symbol" />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'brackets',
}

export const brackets_start = {
  handler: '[',
  label: '[',
  types: ['all'],
  command: 'write',
  dataCy: 'brackets_start',
}

export const brackets_end = {
  handler: ']',
  label: ']',
  types: ['all'],
  command: 'write',
  dataCy: 'brackets_end',
}

export const mixedFraction = {
  handler: '{}\\frac{}{}',
  labelcy: 'mixedFraction',
  value: 'mixedFraction',
  label: (
    <CustomImage
      src={Group941}
      alt="Fraction with numerator and denominator symbol"
      width={39}
      height={39}
    />
  ),
  types: ['all'],
  numToMove: 3,
  command: 'write',
  dataCy: 'mixedFraction',
}

export const exponent = {
  handler: '^',
  labelcy: 'super',
  label: (
    <CustomImage src={Group942} alt="Exponent symbol" width={30} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'exponent',
}

export const exponentBase = {
  handler: '{}^{}',
  labelcy: 'super',
  label: (
    <CustomImage
      src={Group942}
      alt="Raised to the power symbol"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 2,
  dataCy: 'exponentBase',
}

export const subscriptBase = {
  handler: '{{}}_{}',
  labelcy: 'super',
  label: (
    <CustomImage
      src={Group943}
      alt="Subscript base symbol"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 2,
  dataCy: 'subscriptBase',
}

export const sqrt = {
  handler: '\\sqrt',
  labelcy: 'sqrt',
  label: (
    <CustomImage
      src={sqrtIcon}
      alt="Square root symbol"
      width={25}
      height={13}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sqrt',
}

export const mixedSqrt = {
  handler: '\\sqrt[{}]{}',
  label: <CustomImage src={Group999} alt="Mixed Square root symbol" />,
  types: ['all'],
  numToMove: 2,
  command: 'write',
  dataCy: 'mixedSqrt',
}

export const mixedSqrtTree = {
  handler: '\\sqrt[3]{}',
  label: (
    <CustomImage
      src={Group1043}
      alt="Cube root symbol"
      role="presentation"
      width={32}
      height={32}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'mixedSqrtTree',
}

export const plusMinus = {
  handler: '\\pm',
  label: (
    <CustomImage
      src={Group2475}
      alt="Plus-minus symbol"
      width={11}
      height={14}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'plusMinus',
}

export const greaterEqual = {
  handler: '≥',
  label: (
    <CustomImage
      src={Group6199}
      alt="Greater than or equal to symbol"
      width={12}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'greaterEqual',
}

export const strictGreater = {
  handler: '>',
  label: (
    <CustomImage
      src={GreaterThan}
      alt="Greater than symbol"
      width={11}
      height={11}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'strictGreater',
}

export const lessEqual = {
  handler: '≤',
  label: (
    <CustomImage
      src={Group4065}
      alt="Less than or equal to symbol"
      width={12}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'lessEqual',
}

export const strictLess = {
  handler: '<',
  label: (
    <CustomImage src={LessThan} alt="Less than symbol" width={11} height={11} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'strictLess',
}

export const distance = {
  handler: '|',
  labelcy: 'distance',
  label: (
    <CustomImage src={Group993} alt="Distance symbol" width={30} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'distance',
}

export const degree = {
  handler: '\\degree',
  label: (
    <CustomImage src={Group2740} alt="Degree symbol" width={24} height={28} />
  ),
  labelcy: 'degree',
  types: ['all'],
  command: 'cmd',
  dataCy: 'degree',
}

export const imaginaryUnit = {
  handler: 'i',
  label: (
    <CustomImage
      src={Group2715}
      alt="Imaginary unit symbol"
      width={8}
      height={18}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'imaginaryUnit',
}

export const eulerNumber = {
  handler: 'e^{}',
  label: (
    <CustomImage
      src={Group2480}
      alt="e raised to the power symbol"
      width={25}
      height={19.4}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'eulerNumber',
}

export const infinity = {
  handler: '\\infinity',
  label: (
    <CustomImage src={Path3367} alt="Infinity symbol" width={14} height={8} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'infinity',
}

export const logSymbol = {
  handler: '\\log', // handler: "\\iota"
  label: (
    <CustomImage src={log} alt="Logarithm symbol" width={24} height={24} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'logSymbol',
}

export const sine = {
  handler: '\\sin\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2479}
      alt="Sine function symbol"
      width={22}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'sine',
}

export const arcsine = {
  handler: '\\sin^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2251}
      alt="Arcsine function symbol"
      width={32}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'arcsine',
}

export const cosine = {
  handler: '\\cos\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2481}
      alt="Cosine function symbol"
      width={25}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'cosine',
}

export const arccosine = {
  handler: '\\cos^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2247}
      alt="Arccosine function symbol"
      width={33}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'arccosine',
}

export const tangent = {
  handler: '\\tan\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2482}
      alt="Tangent function symbol"
      width={25}
      height={12}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'tangent',
}

export const arctangent = {
  handler: '\\tan^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      alt="Arctangent function symbol"
      src={Trig2243}
      width={34}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'arctangent',
}

export const cotangent = {
  handler: '\\cot\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2483}
      alt="Cotangent function symbol"
      width={24}
      height={12}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'cotangent',
}

export const arccot = {
  handler: '\\cot^{-1}',
  label: (
    <CustomImage
      src={Trig2248}
      alt="Arccotangent function symbol"
      width={33}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'arccot',
}

export const arcsecant = {
  handler: '\\sec^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2249}
      alt="Arcsecant function symbol"
      width={34}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'arcsecant',
}

export const arccosecant = {
  handler: '\\csc^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig2245}
      alt="Arccosecant function symbol"
      width={32}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'arccosecant',
}

export const secant = {
  handler: '\\sec\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig3484}
      alt="Secant function symbol"
      width={25}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'secant',
}

export const cosecant = {
  handler: '\\csc\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig3485}
      alt="Cosecant function symbol"
      width={25}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'cosecant',
}

export const sinh = {
  handler: '\\sinh\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8924}
      alt="Hyperbolic sine function symbol"
      width={33}
      height={13.4}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'sinh',
}

export const inverse_sinh = {
  handler: '\\sinh^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8939}
      alt="Inverse hyperbolic sine function symbol"
      width={40}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_sinh',
}

export const cosh = {
  handler: '\\cosh\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8925}
      alt="Hyperbolic cosine function symbol"
      width={34}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'cosh',
}

export const inverse_cosh = {
  handler: '\\cosh^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8940}
      alt="Inverse hyperbolic cosine function symbol"
      width={42}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_cosh',
}

export const sech = {
  handler: '\\operatorname{sech}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8923}
      alt="Hyperbolic secant function symbol"
      width={34}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'sech',
}

export const inverse_sech = {
  handler: '\\operatorname{sech}^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8941}
      alt="Inverse hyperbolic secant function symbol"
      width={43}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_sech',
}

export const csch = {
  handler: '\\operatorname{csch}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8922}
      alt="Hyperbolic cosecant function symbol"
      width={32.7}
      height={13.4}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'csch',
}

export const inverse_csch = {
  handler: '\\operatorname{csch}^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      alt="Inverse hyperbolic cosecant function symbol"
      src={Trig8942}
      width={40}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_csch',
}

export const tanh = {
  handler: '\\tanh\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8921}
      alt="Hyperbolic tangent function symbol"
      width={35.48}
      height={13.4}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'tanh',
}

export const inverse_tanh = {
  handler: '\\tanh^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8943}
      alt="Inverse hyperbolic tangent function symbol"
      width={41}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_tanh',
}

export const coth = {
  handler: '\\coth\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8920}
      alt="Hyperbolic cotangent function symbol"
      width={33}
      height={13.4}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'coth',
}

export const inverse_coth = {
  handler: '\\coth^{-1}\\left({}\\right)',
  label: (
    <CustomImage
      src={Trig8944}
      alt="Inverse hyperbolic cotangent function symbol"
      width={40}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 1,
  dataCy: 'inverse_coth',
}

export const gmol_inverse = {
  handler: '\\text{g}\\ \\text{mol}^{-1}',
  label: <CustomImage src={Group1054} alt="Gram per mole inverse symbol" />,
  types: ['all'],
  command: 'write',
  dataCy: 'gmol_inverse',
}

export const mm2 = {
  handler: '\\text{mm}^{2}',
  label: (
    <CustomImage
      src={Group8395}
      alt="Square millimeters symbol"
      width={39}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'mm2',
}

export const cm2 = {
  handler: '\\text{cm}^{2}',
  label: (
    <CustomImage
      src={Group8396}
      alt="Square centimeters symbol"
      width={31}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'cm2',
}

export const m2 = {
  handler: '\\text{m}^{2}',
  label: (
    <CustomImage
      src={Group8394}
      alt="Square meters symbol"
      width={23}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'm2',
}

export const km2 = {
  handler: '\\text{km}^{2}',
  label: (
    <CustomImage
      src={Group8393}
      alt="Square kilometers symbol"
      width={32}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'km2',
}

export const si_ug = {
  handler: '\\text{µg}',
  label: (
    <CustomImage
      src={Group8860}
      alt="Micrograms symbol"
      width={18}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_ug',
}

export const si_mg = {
  handler: '\\text{mg}',
  label: (
    <CustomImage
      src={Group8861}
      alt="Milligrams symbol"
      width={24}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_mg',
}

export const si_g = {
  handler: '\\text{g}',
  label: (
    <CustomImage src={Group3378} alt="Grams symbol" width={10} height={14} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_g',
}

export const si_kg = {
  handler: '\\text{kg}',
  label: (
    <CustomImage
      src={Group8862}
      alt="Kilograms symbol"
      width={19}
      height={18}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_kg',
}

export const si_m = {
  handler: '\\text{m}',
  label: (
    <CustomImage src={Group8397} alt="Meters symbol" width={14} height={10} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_m',
}

export const si_km = {
  handler: '\\text{km}',
  label: (
    <CustomImage
      src={Group8398}
      alt="Kilometers symbol"
      width={24}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_km',
}

export const si_um = {
  handler: '\\text{µm}',
  label: (
    <CustomImage
      src={Group8870}
      alt="Micrometers symbol"
      width={24}
      height={20}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_um',
}

export const si_us = {
  handler: '\\text{µs}',
  label: (
    <CustomImage
      src={Group8863}
      alt="Microseconds symbol"
      width={18}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_us',
}

export const si_ms = {
  handler: '\\text{ms}',
  label: (
    <CustomImage
      src={Group8864}
      alt="Milliseconds symbol"
      width={25}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_ms',
}

export const si_s = {
  handler: '\\text{s}',
  label: (
    <CustomImage src={Path3530} alt="Seconds symbol" width={8} height={20} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_s',
}

export const si_ml = {
  handler: '\\text{mL}',
  label: (
    <CustomImage
      src={Path3532}
      alt="Milliliters symbol"
      width={26}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_ml',
}

export const si_l = {
  handler: '\\text{L}',
  label: (
    <CustomImage src={Path3531} alt="Liters symbol" width={8} height={20} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_l',
}

export const si_per_ms = {
  handler: '\\text{m/s}',
  label: (
    <CustomImage
      src={Group8865}
      alt="Meters per second symbol"
      width={32}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_per_ms',
}

export const si_per_ms2 = {
  handler: '\\text{m/}s^2',
  label: (
    <CustomImage
      src={Group8866}
      alt="Meters per second squared symbol"
      width={38}
      height={25}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_per_ms2',
}

export const si_mm = {
  handler: '\\text{mm}',
  label: (
    <CustomImage
      src={Group8871}
      alt="Millimeters symbol"
      width={30}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_mm',
}

export const si_cm = {
  handler: '\\text{cm}',
  label: (
    <CustomImage
      src={Group8872}
      alt="Centimeters symbol"
      width={23}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_cm',
}

export const si_mm3 = {
  handler: '\\text{mm}^3',
  label: (
    <CustomImage
      src={Group8867}
      alt="Millimeters cubed symbol"
      width={42}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_mm3',
}

export const si_cm3 = {
  handler: '\\text{cm}^3',
  label: (
    <CustomImage
      src={Group8868}
      alt="Centimeters cubed symbol"
      width={32}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_cm3',
}

export const si_cm_sec = {
  handler: '\\text{cm/s}',
  label: (
    <CustomImage
      src={Group8873}
      alt="Centimeters per second symbol"
      width={40}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_cm_sec',
}

export const si_m3 = {
  handler: '\\text{m}^3',
  label: (
    <CustomImage
      src={Group8869}
      alt="Meters cubed symbol"
      width={23}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_m3',
}

export const si_km3 = {
  handler: '\\text{km}^3',
  label: (
    <CustomImage
      src={Group8878}
      alt="Kilometers cubed symbol"
      width={30}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_km3',
}

export const si_kgm3 = {
  handler: '\\text{kg/}m^3',
  label: (
    <CustomImage
      src={Group8558}
      alt="Kilograms per meter cubed symbol"
      width={40}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_kgm3',
}

export const si_km_sec = {
  handler: '\\text{km/s}',
  label: (
    <CustomImage
      src={Group8874}
      alt="Kilometers per second symbol"
      width={40}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_km_sec',
}

export const si_gcm3 = {
  handler: '\\text{g/}cm^3',
  label: (
    <CustomImage
      src={Group8559}
      alt="Grams per cubic centimeter symbol"
      width={40}
      height={20}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'si_gcm3',
}

export const us_in2 = {
  handler: '\\text{in}^{2}',
  label: (
    <CustomImage
      src={Group8557}
      alt="Inches squared symbol"
      width={22}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_in2',
}

export const us_oz = {
  handler: '\\text{oz}',
  label: (
    <CustomImage src={Path3534} alt="Ounces symbol" width={19} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_oz',
}

export const us_lb = {
  handler: '\\text{lb}',
  label: (
    <CustomImage src={Path3535} alt="Pounds symbol" width={16} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_lb',
}

export const us_in = {
  handler: '\\text{in}',
  label: (
    <CustomImage src={Path3536} alt="Inches symbol" width={16} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_in',
}
export const us_ft = {
  handler: '\\text{ft}',
  label: (
    <CustomImage src={Path3537} alt="Feet symbol" width={14} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_ft',
}

export const us_ft2 = {
  handler: '\\text{ft}^{2}',
  label: (
    <CustomImage
      src={Group8877}
      alt="Square feet symbol"
      width={20}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_ft2',
}

export const us_feet = {
  handler: '\\text{feet}',
  label: 'feet',
  types: ['all'],
  command: 'write',
  dataCy: 'us_feet',
}

export const us_sq_mi = {
  handler: '\\text{sq mi}',
  label: (
    <CustomImage src={Sqmi} alt="Square miles symbol" width={38} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_sq_mi',
}

export const us_yard = {
  handler: '\\text{yard}',
  label: 'yard',
  types: ['all'],
  command: 'write',
  dataCy: 'us_yard',
}

export const us_acre = {
  handler: '\\text{acre}',
  label: (
    <CustomImage src={GroupAcre} alt="Acre symbol" width={38} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_acre',
}

export const us_cup = {
  handler: '\\text{cup}',
  label: 'cup',
  types: ['all'],
  command: 'write',
  dataCy: 'us_cup',
}

export const us_qt = {
  handler: '\\text{qt}',
  label: 'qt',
  types: ['all'],
  command: 'write',
  dataCy: 'us_qt',
}

export const us_tbsp = {
  handler: '\\text{tbsp}',
  label: 'tbsp',
  types: ['all'],
  command: 'write',
  dataCy: 'us_tbsp',
}

export const us_ton = {
  handler: '\\text{ton}',
  label: 'ton',
  types: ['all'],
  command: 'write',
  dataCy: 'us_ton',
}

export const us_tsp = {
  handler: '\\text{tsp}',
  label: 'tsp',
  types: ['all'],
  command: 'write',
  dataCy: 'us_tsp',
}

export const us_mph = {
  handler: '\\text{mph}',
  label: 'mph',
  types: ['all'],
  command: 'write',
  dataCy: 'us_mph',
}

export const us_fps = {
  handler: '\\text{fps}',
  label: 'fps',
  types: ['all'],
  command: 'write',
  dataCy: 'us_fps',
}

export const us_ft_2 = {
  handler: '\\text{ft/sec}^2',
  label: (
    <span role="presentation">
      ft/sec<sup>2</sup>
    </span>
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_ft_2',
}

export const us_in_3 = {
  handler: '\\text{in}^3',
  label: (
    <span role="presentation">
      in<sup>3</sup>
    </span>
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_in_3',
}

export const us_mi_3 = {
  handler: '\\text{mi}^3',
  label: (
    <span role="presentation">
      mi<sup>3</sup>
    </span>
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_mi_3',
}

export const us_ft_3 = {
  handler: '\\text{ft}^3',
  label: (
    <span role="presentation">
      ft<sup>3</sup>
    </span>
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_ft_3',
}

export const us_mi = {
  handler: '\\text{mi}',
  label: (
    <CustomImage src={Path3538} alt="Mile symbol" width={22} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_mi',
}

export const us_mi2 = {
  handler: '\\text{mi}^{2}',
  label: (
    <CustomImage
      src={Group8876}
      alt="Square mile symbol"
      width={30}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_mi2',
}

export const us_fl_oz = {
  handler: '\\text{fl oz}',
  label: (
    <CustomImage
      src={Path3539}
      alt="Fluid ounce symbol"
      width={34}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_fl_oz',
}

export const us_pt = {
  handler: '\\text{pt}',
  label: (
    <CustomImage src={Path3540} alt="Pint symbol" width={18} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_pt',
}

export const us_gal = {
  handler: '\\text{gal}',
  label: (
    <CustomImage src={Path3541} alt="Gallon symbol" width={24} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_gal',
}

export const us_second = {
  handler: '\\text{second}',
  label: (
    <CustomImage src={Path3512} alt="Second symbol" width={40} height={9} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_second',
}

export const us_minutes = {
  handler: '\\text{min}',
  label: (
    <CustomImage src={Path3548} alt="Minute symbol" width={32} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_minutes',
}

export const us_hour = {
  handler: '\\text{hour}',
  label: (
    <CustomImage src={Path3549} alt="Hour symbol" width={40} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'us_hour',
}

export const similarity = {
  handler: '\\sim',
  label: (
    <CustomImage
      src={Group3167}
      alt="Similarity symbol"
      width={16}
      height={5}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'similarity',
}

export const equivalence = {
  handler: '\\equiv',
  label: (
    <CustomImage
      src={Group2751}
      alt="Equivalence symbol"
      width={14}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'equivalence',
}

export const approximation = {
  handler: '\\approx',
  label: (
    <CustomImage
      src={Group2745}
      alt="Approximation symbol"
      width={16}
      height={12}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'approximation',
}

export const simeq = {
  handler: '\\simeq',
  label: (
    <CustomImage
      src={Group2752}
      alt="Approximately equal symbol"
      width={16}
      height={12}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'simeq',
}

export const congruent = {
  handler: '\\cong',
  label: (
    <CustomImage
      src={Group3168}
      alt="Congruent symbol"
      width={16}
      height={12}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'congruent',
}

export const lneq = {
  handler: '≮',
  label: (
    <CustomImage
      src={Group8360}
      alt="Not less than symbol"
      width={11}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'lneq',
}

export const gnep = {
  handler: '≯',
  label: (
    <CustomImage
      src={Group8380}
      alt="Not greater than symbol"
      width={11}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'gnep',
}

export const strictSubset = {
  handler: '\\subset',
  label: (
    <CustomImage
      src={Path2256}
      alt="Strict subset symbol"
      width={12}
      height={12}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'strictSubset',
}

export const strictSuperset = {
  handler: '\\supset',
  label: (
    <CustomImage
      src={Path2253}
      alt="Strict superset symbol"
      width={12}
      height={12}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'strictSuperset',
}

export const subset = {
  handler: '\\subseteq',
  label: (
    <CustomImage src={Path2263} alt="Subset symbol" width={12} height={14} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'subset',
}

export const nesubset = {
  handler: '\\subseteq',
  label: (
    <CustomImage src={Path2263} alt="Subset symbol" width={12} height={14} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'nesubset',
}

export const superset = {
  handler: '\\supseteq',
  label: (
    <CustomImage
      src={Path2261}
      alt="Superset or equal to symbol"
      width={12}
      height={14}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'superset',
}

export const belongsTo = {
  handler: '\\in',
  label: (
    <CustomImage
      src={Group2267}
      alt="Belongs to symbol"
      width={14}
      height={14}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'belongsTo',
}

export const notBelongsTo = {
  handler: '\\notin',
  label: (
    <CustomImage
      src={Path2266}
      alt="Not belongs to symbol"
      width={14}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'notBelongsTo',
}

export const union = {
  handler: '\\union',
  label: (
    <CustomImage src={Path2252} alt="Union symbol" width={12} height={15} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'union',
}

export const intersection = {
  handler: '\\intersection',
  label: (
    <CustomImage
      src={Path2254}
      alt="Intersection symbol"
      width={12}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'intersection',
}

export const square = {
  handler: '\\square',
  label: (
    <CustomImage src={Group3180} alt="Square symbol" width={14} height={14} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'square',
}

export const triangle = {
  handler: '\\triangle',
  label: (
    <CustomImage src={Group3169} alt="Triangle symbol" width={12} height={15} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'triangle',
}

export const bigcirc = {
  handler: '\\bigcirc',
  label: (
    <CustomImage
      src={Group3177}
      alt="Big circle symbol"
      width={12}
      height={12}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'bigcirc',
}

export const o_dot = {
  handler: '\\odot',
  label: (
    <CustomImage
      src={Group3170}
      alt="Dot multiplication symbol"
      width={16}
      height={16}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'o_dot',
}

export const parallelogram = {
  handler: '\\parallelogram',
  label: (
    <CustomImage
      src={Group3172}
      alt="Parallelogram symbol"
      width={31}
      height={11}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'parallelogram',
}

export const parallel = {
  handler: '\\parallel',
  label: (
    <CustomImage src={Group3163} alt="Parallel symbol" width={7} height={21} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'parallel',
}

export const perp = {
  handler: '\\perp',
  label: (
    <CustomImage
      src={Group3171}
      alt="Perpendicular symbol"
      width={16}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'perp',
}

export const mathbb_c = {
  handler: '\\C', // in katex \mathbb{C}
  label: (
    <CustomImage
      src={Path3362}
      alt="Mathematical symbol for complex numbers"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_c',
}

export const mathbb_q = {
  handler: '\\Q', // in katex \mathbb{Q}
  label: (
    <CustomImage
      src={Path3357}
      alt="Mathematical symbol for rational numbers"
      width={16}
      height={20}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_q',
}

export const mathbb_r = {
  handler: '\\R', // in katex \mathbb{R}
  label: (
    <CustomImage
      src={Path3358}
      alt="Mathematical symbol for real numbers"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_r',
}

export const mathbb_n = {
  handler: '\\N', // in katex \mathbb{N}
  label: (
    <CustomImage
      src={Path3504}
      alt="Mathematical symbol for natural numbers"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_n',
}

export const mathbb_u = {
  handler: '\\U', // in katex \mathbb{U}
  label: (
    <CustomImage
      src={Path3505}
      alt="Mathematical symbol for some universal set"
      width={14}
      height={18}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_u',
}

export const mathbb_w = {
  handler: '\\W', // in katex \mathbb{W}, in mathQuill need to add command
  label: (
    <CustomImage
      src={Path3360}
      alt="Mathematical symbol for W"
      width={22}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_w',
}

export const mathbb_z = {
  handler: '\\Z', // in katex \mathbb{Z}
  label: (
    <CustomImage
      src={Path3359}
      alt="Mathematical symbol for integers"
      width={12}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'mathbb_z',
}

export const angle = {
  handler: '\\angle',
  label: (
    <CustomImage src={Path2744} alt="Angle symbol" width={14} height={12} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'angle',
}

export const m_angle = {
  handler: '\\text{m}\\angle',
  label: (
    <CustomImage
      src={Group6101}
      alt="Measure of angle symbol"
      width={29}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'm_angle',
}

export const nparallel = {
  handler: '\\nparallel',
  label: (
    <CustomImage
      src={Group3181}
      alt="Not parallel symbol"
      width={10}
      height={20}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'nparallel',
}

export const ni_symbole = {
  handler: '\\ni',
  label: (
    <CustomImage
      src={Path2262}
      alt="Does not belong to symbol"
      width={12}
      height={12}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'ni_symbole',
}

export const not_ni_symbole = {
  handler: '\\notni',
  label: (
    <CustomImage
      src={Path2743}
      alt="Not belongs to symbol"
      width={12}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'not_ni_symbole',
}

export const forall = {
  handler: '\\forall',
  label: (
    <CustomImage src={Path2792} alt="For all symbol" width={13} height={14} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'forall',
}

export const diamond = {
  handler: '\\diamond',
  label: (
    <CustomImage src={Path3526} alt="Diamond symbol" width={17} height={20} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'diamond',
}

export const diamondsuit = {
  handler: '\\diamondsuit',
  label: (
    <CustomImage
      src={Path3523}
      alt="Diamond suit symbol"
      width={17}
      height={20}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'diamondsuit',
}

export const star = {
  handler: '\\star',
  label: (
    <CustomImage src={Path3529} alt="Star symbol" width={24} height={37} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'star',
}

export const clubsuit = {
  handler: '\\clubsuit',
  label: (
    <CustomImage src={Path3522} alt="Club suit symbol" width={21} height={21} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'clubsuit',
}

export const spadesuit = {
  handler: '\\spadesuit',
  label: (
    <CustomImage
      src={Path3524}
      alt="Spade suit symbol"
      width={21}
      height={21}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'spadesuit',
}

export const heartsuit = {
  handler: '\\heartsuit',
  label: (
    <CustomImage
      src={Path3525}
      alt="Heart suit symbol"
      width={21}
      height={18}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'heartsuit',
}

export const backslash = {
  handler: '\\backslash',
  label: (
    <CustomImage src={Path3521} alt="Backslash symbol" width={10} height={38} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'backslash',
}

export const flat = {
  handler: '\\flat',
  label: (
    <CustomImage src={Path3501} alt="Flat symbol" width={10} height={34} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'flat',
}

export const natural = {
  handler: '\\natural',
  label: (
    <CustomImage src={Path3502} alt="Natural symbol" width={10} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'natural',
}

export const sharp = {
  handler: '\\sharp',
  label: (
    <CustomImage src={Path3503} width={10} alt="Sharp symbol" height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sharp',
}

export const leftarrow = {
  handler: '\\leftarrow',
  label: (
    <CustomImage
      src={Group8879}
      alt="Left arrow symbol"
      width={23}
      height={11}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'leftarrow',
}

export const rightarrow = {
  handler: '\\rightarrow',
  label: (
    <CustomImage
      src={Group8880}
      alt="Right arrow symbol"
      width={23}
      height={11}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'rightarrow',
}

export const uparrow = {
  handler: '\\uparrow',
  label: (
    <CustomImage src={Group8881} alt="Up arrow symbol" width={10} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'uparrow',
}

export const downarrow = {
  handler: '\\downarrow',
  label: (
    <CustomImage
      src={Group8882}
      alt="Down arrow symbol"
      width={10}
      height={22}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'downarrow',
}

export const longleftrightarrow = {
  handler: '\\longleftrightarrow',
  label: (
    <CustomImage
      src={Group8883}
      alt="Long left-right arrow symbol"
      width={24}
      height={10}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'longleftrightarrow',
}

export const rightleftharpoons = {
  handler: '\\rightleftharpoons',
  label: (
    <CustomImage
      src={Group8854}
      alt="Right-left harpoons symbol"
      width={19}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'rightleftharpoons',
}

export const rightleftarrows = {
  handler: '\\rightleftarrows',
  label: (
    <CustomImage
      src={Chem520}
      alt="Right-left arrows symbol"
      width={19}
      height={15}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'rightleftarrows',
}

export const lceil = {
  handler: '\\lceil',
  label: (
    <CustomImage
      src={Group8885}
      alt="Left ceiling symbol"
      width={8}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'lceil',
}

export const rceil = {
  handler: '\\rceil',
  label: (
    <CustomImage
      src={Group8886}
      alt="Right ceiling symbol"
      width={8}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'rceil',
}

export const big_rightarrow = {
  handler: '\\Rightarrow',
  label: (
    <CustomImage
      src={Group8887}
      alt="Big right arrow symbol"
      width={20}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'big_rightarrow',
}

export const v_dot = {
  handler: '\\vdots',
  label: (
    <CustomImage
      src={Group8888}
      alt="Vertical dots symbol"
      width={6}
      height={25}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'vdots',
  dataCy: 'v_dot',
}

export const l_dots = {
  handler: '\\ldots',
  label: (
    <CustomImage
      src={Group8889}
      alt="Horizontal dots symbol"
      width={25}
      height={6}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'ldots',
  dataCy: 'l_dots',
}

export const d_dots = {
  handler: '\\ddots',
  label: (
    <CustomImage
      src={Group8890}
      alt="Diagonal dots symbol"
      width={22}
      height={22}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'ldoddotsts',
  dataCy: 'd_dots',
}

export const therefore = {
  handler: '\\therefore',
  label: (
    <CustomImage
      src={Group8891}
      alt="Therefore symbol"
      width={17}
      height={16}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'therefore',
}

export const because = {
  handler: '\\because',
  label: (
    <CustomImage src={Group8892} alt="Because symbol" width={17} height={16} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'because',
}

export const v_two_dots = {
  handler: ':',
  label: (
    <CustomImage src={Group8893} alt="Colon symbol" width={6} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'v_two_dots',
}

export const neg = {
  handler: '\\neg',
  label: (
    <CustomImage
      src={Group8894}
      alt="Negation symbol"
      width={7}
      height={17}
      style={{ transform: 'rotate(90deg)' }}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'neg',
}

export const nrightarrow = {
  handler: '\\nrightarrow',
  label: (
    <CustomImage
      src={Group8896}
      alt="Not right arrow symbol"
      width={25}
      height={11}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'nrightarrow',
}

export const nleftrightarrow = {
  handler: '\\nleftrightarrow',
  label: (
    <CustomImage
      src={Group8895}
      alt="Not left-right arrow symbol"
      width={25}
      height={11}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'nleftrightarrow',
}

export const alpha = {
  handler: '\\alpha',
  label: (
    <CustomImage src={Path3391} alt="Alpha symbol" width={14} height={14} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'alpha',
}

export const beta = {
  handler: '\\beta',
  label: (
    <CustomImage src={Path3399} alt="Beta symbol" width={13} height={24} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'beta',
}

export const gamma = {
  handler: '\\gamma',
  label: (
    <CustomImage src={Path3405} alt="Gamma symbol" width={13} height={18} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'gamma',
}

export const delta = {
  handler: '\\delta',
  label: (
    <CustomImage src={Path3411} alt="Delta symbol" width={13} height={18} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'natudeltaral',
}

export const varepsilon = {
  handler: '\\varepsilon',
  label: (
    <CustomImage
      src={Path3417}
      alt="Varepsilon symbol"
      width={14}
      height={14}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'varepsilon',
}

export const zeta = {
  handler: '\\zeta',
  label: (
    <CustomImage src={Path3423} alt="Zeta symbol" width={14} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'zeta',
}

export const eta = {
  handler: '\\eta',
  label: <CustomImage src={Path3429} width={12} height={19} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'eta',
}

export const theta = {
  handler: '\\theta',
  label: (
    <CustomImage src={Path3436} alt="Theta symbol" width={12} height={19} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'theta',
}

export const vartheta = {
  handler: '\\vartheta',
  label: (
    <CustomImage src={Path3444} alt="Vartheta symbol" width={16} height={19} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'vartheta',
}

export const iota = {
  handler: '\\iota',
  label: <CustomImage src={Path3392} alt="Iota symbol" width={8} height={15} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'iota',
}

export const kappa = {
  handler: '\\kappa',
  label: (
    <CustomImage src={Path3400} alt="Kappa symbol" width={12} height={13} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'kappa',
}

export const lambda = {
  handler: '\\lambda',
  label: (
    <CustomImage src={Path3406} alt="Lambda symbol" width={13} height={18} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'lambda',
}

export const mu = {
  handler: '\\mu',
  label: <CustomImage src={Path3412} alt="Mu symbol" width={13} height={19} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'mu',
}

export const nu = {
  handler: '\\nu',
  label: <CustomImage src={Path3418} alt="Nu symbol" width={13} height={13} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'nu',
}

export const xi = {
  handler: '\\xi',
  label: <CustomImage src={Path3424} alt="Xi symbol" width={11} height={22} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'xi',
}

export const greek_o = {
  handler: 'o',
  label: (
    <CustomImage
      src={Path3430}
      alt="Greek small letter o"
      width={13}
      height={13}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'greek_o',
}

export const piSymbol = {
  handler: '\\pi',
  label: <CustomImage src={Path3437} alt="Pi symbol" width={15} height={13} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'piSymbol',
}

export const varpi = {
  handler: '\\varpi',
  label: (
    <CustomImage
      src={Path3395}
      alt="Variant of Pi symbol"
      width={21}
      height={13}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'varpi',
}

export const rho = {
  handler: '\\rho',
  label: <CustomImage src={Path3394} alt="Rho symbol" width={12} height={19} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'rho',
}

export const varsigma = {
  handler: '\\varsigma',
  label: (
    <CustomImage
      src={Path3401}
      alt="Variant of sigma symbol"
      width={10}
      height={18}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'varsigma',
}

export const sigma = {
  handler: '\\sigma',
  label: (
    <CustomImage src={Path3407} alt="Sigma symbol" width={13} height={13} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sigma',
}

export const tau = {
  handler: '\\tau',
  label: <CustomImage src={Path3413} alt="Tau symbol" width={11} height={13} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'tau',
}

export const upsilon = {
  handler: '\\upsilon',
  label: (
    <CustomImage src={Path3419} alt="Upsilon symbol" width={12} height={13} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upsilon',
}

export const varphi = {
  handler: '\\varphi',
  label: (
    <CustomImage
      src={Path3425}
      alt="Variant of phi symbol"
      width={16}
      height={19}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'varphi',
}

export const phi = {
  handler: '\\phi',
  label: <CustomImage src={Path3432} alt="Phi symbol" width={18} height={18} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'phi',
}

export const chi = {
  handler: '\\chi',
  label: <CustomImage src={Path3431} alt="Chi symbol" width={14} height={18} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'chi',
}

export const psi = {
  handler: '\\psi',
  label: <CustomImage src={Path3438} alt="Psi symbol" width={16} height={24} />,
  types: ['all'],
  command: 'cmd',
  dataCy: 'psi',
}

export const omega = {
  handler: '\\omega',
  label: (
    <CustomImage src={Path3396} alt="Omega symbol" width={17} height={13} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'omega',
}

export const upper_alpha = {
  handler: '\\Alpha',
  label: (
    <CustomImage
      src={Path3402}
      alt="Uppercase Alpha symbol"
      width={16}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_alpha',
}

export const upper_beta = {
  handler: '\\Beta',
  label: (
    <CustomImage
      src={Path3408}
      alt="Uppercase Beta symbol"
      width={12}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_beta',
}

export const upper_gamma = {
  handler: '\\Gamma',
  label: (
    <CustomImage
      src={Path3414}
      alt="Uppercase Gamma symbol"
      width={10}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_gamma',
}

export const upper_delta = {
  handler: '\\Delta',
  label: (
    <CustomImage
      src={Path3420}
      alt="Uppercase Delta symbol"
      width={14}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_delta',
}

export const upper_epsilon = {
  handler: '\\Epsilon',
  label: (
    <CustomImage
      src={Path3426}
      alt="Uppercase Epsilon symbol"
      width={10}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_epsilon',
}

export const upper_zeta = {
  handler: '\\Zeta',
  label: (
    <CustomImage
      src={Path3433}
      alt="Uppercase Zeta symbol"
      width={12}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_zeta',
}

export const upper_eta = {
  handler: '\\Eta',
  label: (
    <CustomImage
      src={Path3441}
      alt="Uppercase Eta symbol"
      width={14}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_eta',
}

export const upper_theta = {
  handler: '\\Theta',
  label: (
    <CustomImage
      src={Path3439}
      alt="Uppercase Theta symbol"
      width={16}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_theta',
}

export const upper_iota = {
  handler: '\\Iota',
  label: (
    <CustomImage
      src={Path3397}
      alt="Uppercase Iota symbol"
      width={4}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_iota',
}

export const upper_kappa = {
  handler: '\\Kappa',
  label: (
    <CustomImage
      src={Path3403}
      alt="Uppercase Kappa symbol"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_kappa',
}

export const upper_lambda = {
  handler: '\\Lambda',
  label: (
    <CustomImage
      src={Path3409}
      alt="Uppercase Lambda symbol"
      width={15}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_lambda',
}

export const upper_mu = {
  handler: 'Μ',
  label: (
    <CustomImage
      src={Path3415}
      alt="Uppercase Mu symbol"
      width={18}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'Mu',
  dataCy: 'upper_mu',
}

export const upper_nu = {
  handler: 'Ν',
  label: (
    <CustomImage
      src={Path3421}
      alt="Uppercase Nu symbol"
      width={14}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'Nu',
  dataCy: 'upper_nu',
}

export const upper_xi = {
  handler: '\\Xi',
  label: (
    <CustomImage
      src={Path3427}
      alt="Uppercase Xi symbol"
      width={12}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_xi',
}

export const upper_omicron = {
  handler: 'Ο',
  label: (
    <CustomImage
      src={Path3434}
      alt="Uppercase Omicron symbol"
      width={16}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'Omicron',
  dataCy: 'upper_omicron',
}

export const upper_pi = {
  handler: '\\Pi',
  label: (
    <CustomImage
      src={Path3442}
      alt="Uppercase Pi symbol"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_pi',
}

export const upper_rho = {
  handler: '\\Rho',
  label: (
    <CustomImage
      src={Path3454}
      alt="Uppercase Rho symbol"
      width={11}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_rho',
}

export const upper_sigma = {
  handler: '\\Sigma',
  label: (
    <CustomImage
      src={Path3455}
      alt="Uppercase Sigma symbol"
      width={12}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_sigma',
}

export const upper_tau = {
  handler: '\\Tau',
  label: (
    <CustomImage
      src={Path3456}
      alt="Uppercase Tau symbol"
      width={13}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_tau',
}

export const upper_upsilon = {
  handler: '\\Upsilon',
  label: (
    <CustomImage
      src={Path3457}
      alt="Uppercase Upsilon symbol"
      width={14}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_upsilon',
}

export const upper_phi = {
  handler: '\\Phi',
  label: (
    <CustomImage
      src={Path3458}
      alt="Uppercase Phi symbol"
      width={17}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_phi',
}

export const upper_chi = {
  handler: '\\Chi',
  label: (
    <CustomImage
      src={Path3459}
      alt="Uppercase Chi symbol"
      width={15}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_chi',
}

export const upper_psi = {
  handler: '\\Psi',
  label: (
    <CustomImage
      src={Path3460}
      alt="Uppercase Psi symbol"
      width={18}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_psi',
}

export const upper_omega = {
  handler: '\\Omega',
  label: (
    <CustomImage
      src={Path3461}
      alt="Uppercase Omega symbol"
      width={17}
      height={17}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'upper_omega',
}

export const bmatrix = {
  handler: '\\bmatrix',
  label: (
    <CustomImage src={Group1004} alt="Bmatrix symbol" width={38} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  name: 'bmatrix',
  dataCy: 'bmatrix',
}

export const bmatrix_v_one = {
  handler: '\\begin{bmatrix}{}\\\\{}\\end{bmatrix}',
  label: (
    <CustomImage
      src={Group1008}
      alt="Bmatrix with vertical one"
      width={28}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  name: 'bmatrix',
  dataCy: 'bmatrix_v_one',
}

export const bmatrix_h_one = {
  handler: '\\begin{bmatrix}{}&{}\\end{bmatrix}',
  label: (
    <CustomImage
      src={Group1009}
      alt="Bmatrix with horizontal one"
      width={38}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  name: 'bmatrix',
  dataCy: 'bmatrix_h_one',
}

export const bmatrix_three = {
  handler: '\\begin{bmatrix}{}&{}&{}\\\\{}&{}&{}\\\\{}&{}&{}\\end{bmatrix}',
  label: (
    <CustomImage src={Group1005} alt="3x3 Bmatrix" width={40} height={28} />
  ),
  types: ['all'],
  command: 'write',
  name: 'tripleMatrix',
  dataCy: 'bmatrix_three',
}

export const vmatrix = {
  handler: '\\begin{vmatrix}{}&{}\\\\{}&{}\\end{vmatrix}',
  label: (
    <CustomImage src={Group1015} alt="2x2 Vmatrix" width={38} height={22} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'vmatrix',
}

export const shift_matrix = {
  handler: 'Shift-Spacebar',
  label: (
    <CustomImage src={Group1006} alt="Shift matrix" width={26} height={35} />
  ),
  types: ['all'],
  command: 'keystroke',
  name: 'shiftSpacebar',
  dataCy: 'shift_matrix',
}

export const shift_enter_matrix = {
  handler: 'Shift-Enter',
  label: (
    <CustomImage
      src={Group1007}
      alt="Shift enter matrix"
      width={30}
      height={25}
    />
  ),
  types: ['all'],
  command: 'keystroke',
  name: 'shiftEnter',
  dataCy: 'shift_enter_matrix',
}

export const left_matrix = {
  handler: '\\almatrix',
  label: (
    <CustomImage
      src={Group1016}
      alt="Left-aligned matrix"
      width={26}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'left_matrix',
}

export const left_matrix_two = {
  handler: '\\begin{almatrix}&\\\\&\\end{almatrix}',
  label: (
    <CustomImage
      src={Group1020}
      alt="Left-aligned 2x2 matrix"
      width={35.7}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 4,
  dataCy: 'left_matrix_two',
}

export const right_matrix = {
  handler: '\\armatrix',
  label: (
    <CustomImage
      src={Group1017}
      alt="Right-aligned matrix"
      width={26}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'right_matrix',
}

// TODO: implement this command
export const sq_matrix = {
  handler: '\\armatrix',
  label: (
    <CustomImage src={Group1018} alt="Square matrix" width={32} height={37} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'sq_matrix',
}

export const plus_matrix = {
  handler: '\\begin{array}{r}&\\\\+&\\\\\\hline&\\end{array}',
  label: (
    <CustomImage src={Group1019} alt="Plus matrix" width={30} height={39.5} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'plus_matrix',
}

export const line_matrix = {
  handler: '\\begin{array}{r}\\\\\\\\\\hline\\end{array}',
  label: (
    <CustomImage src={Group1021} alt="Line_matrix" width={30} height={39.5} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'line_matrix',
}

export const minus_matrix = {
  handler: '\\begin{array}{r}&\\\\-&\\\\\\hline&\\end{array}',
  label: (
    <CustomImage src={Group1003} alt="Minus matrix" width={30} height={39.5} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'minus_matrix',
}

export const time_matrix = {
  handler: '\\begin{array}{r}&\\\\\\times&\\\\\\hline&\\end{array}',
  label: (
    <CustomImage src={Group1002} alt="Times matrix" width={30} height={39.5} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'time_matrix',
}

export const xrightarrow = {
  handler: '\\xrightarrow[{}]{}',
  label: (
    <CustomImage
      src={Group1022}
      alt="Rightward arrow symbol"
      width={23}
      height={37}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'xrightarrow',
}

export const overline = {
  handler: '\\overline',
  label: (
    <CustomImage src={Group6104} alt="Overline symbol" width={16} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'overline',
}

export const overline_r_arrow = {
  handler: '\\overrightarrow',
  label: (
    <CustomImage
      src={Group6102}
      alt="Rightward arrow with overline"
      width={18}
      height={31}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'overline_r_arrow',
}

export const overline_l_r_arrow = {
  handler: '\\overleftrightarrow',
  label: (
    <CustomImage
      src={Group6103}
      alt="Left-right overline arrow"
      width={20}
      height={31}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'overline_l_r_arrow',
}

export const over_arc = {
  handler: '\\overarc',
  label: (
    <CustomImage src={Group1053} alt="Arc symbol" width={14} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'over_arc',
}

export const hat = {
  handler: '\\hat',
  label: (
    <CustomImage src={Group1055} alt="Hat symbol" width={14} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'hat_symbole',
}

export const under_sim = {
  handler: '\\undersim',
  label: (
    <CustomImage
      src={Group1052}
      alt="Under sim symbol"
      width={14}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'under_sim',
}

export const braces = {
  handler: '{}',
  label: (
    <CustomImage src={Group1023} alt="Braces symbol" width={34} height={22} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'braces',
}

export const left_bracket = {
  handler: '\\left[\\right)',
  label: (
    <CustomImage
      src={Group1025}
      alt="Left bracket symbol"
      width={30}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'left_bracket',
}

export const right_bracket = {
  handler: '\\left(\\right]',
  label: (
    <CustomImage
      src={Group1045}
      alt="Right bracket symbol"
      width={30}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'right_bracket',
}

export const sum = {
  handler: '\\sum',
  label: (
    <CustomImage
      src={Group1030}
      alt="Summation symbol"
      width={11}
      height={38}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sum',
}

export const sum_symbol = {
  handler: '\\summationSymbol',
  label: (
    <CustomImage
      src={Group1031}
      alt="Summation symbol variant"
      width={11}
      height={38}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sum_symbol',
}

export const sum_bottom = {
  handler: '\\summationLower',
  label: (
    <CustomImage
      src={Group1032}
      alt="Summation with lower limit"
      width={11}
      height={38}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sum_bottom',
}

export const sum_upper = {
  handler: '\\sumUpper',
  label: (
    <CustomImage
      src={Group1033}
      alt="Summation with upper limit"
      width={11}
      height={38}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'sum_upper',
}

export const no_subset = {
  handler: '\\nsubset',
  label: (
    <CustomImage src={Path3478} alt="No subset symbol" width={14} height={20} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'no_subset',
}

export const no_subset_eq = {
  handler: '\\nsubseteq',
  label: (
    <CustomImage
      src={Path3479}
      alt="No subset equal symbol"
      width={12}
      height={18}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'no_subset_eq',
}

export const empty_set = {
  handler: '\\emptyset',
  label: (
    <CustomImage src={Path3476} alt="Empty set symbol" width={13} height={15} />
  ),
  types: ['all', 'sets'],
  command: 'cmd',
  dataCy: 'empty_set',
}

export const exist_set = {
  handler: '\\exist',
  label: (
    <CustomImage
      src={Path3475}
      alt="Existential quantifier symbol"
      width={8}
      height={14}
    />
  ),
  types: ['all', 'discrete'],
  command: 'cmd',
  dataCy: 'exist_set',
}

export const circle_plus = {
  handler: '\\oplus',
  label: (
    <CustomImage
      src={Path3477}
      alt="Circle plus symbol"
      width={14}
      height={14}
    />
  ),
  types: ['all', 'discrete'],
  command: 'cmd',
  dataCy: 'circle_plus',
}

export const derivative = {
  handler: '\\frac{\\operatorname d{}}{\\operatorname d{}}',
  label: (
    <CustomImage
      src={Group6105}
      alt="Derivative symbol"
      width={35}
      height={39}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'derivative',
}

export const derivative_alpha = {
  handler: '\\frac{\\partial{}}{\\partial{}}',
  label: (
    <CustomImage
      src={Group6106}
      alt="Partial derivative symbol"
      width={35}
      height={39}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'derivative_alpha',
}

export const integral = {
  handler: '\\int',
  label: (
    <CustomImage src={Group1029} alt="Integral symbol" width={28} height={39} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'integral',
}

export const limit = {
  handler: '\\lim_{x\\to {}}',
  label: (
    <CustomImage src={Group1058} alt="Limit symbol" width={34} height={26} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'limit',
}

export const frac_H = {
  handler: '\\frac{\\mathrm{}}{\\mathrm{}}H',
  label: (
    <CustomImage src={Group1012} alt="Fraction H" width={24} height={40} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'frac_H',
}

export const mathrm_H = {
  handler: 'H\\frac{\\mathrm{}}{\\mathrm{}}',
  label: (
    <CustomImage
      src={Group1013}
      alt="H of the fraction with numerator and denominator symbol"
      width={29}
      height={40}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'mathrm_H',
}

export const mathrm = {
  handler: '\\mathrm{}^{}',
  label: (
    <CustomImage
      src={Group942}
      alt="Number and exponent symbol"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 3,
  dataCy: 'kamathrmppa',
}

export const mathrm_under = {
  handler: '\\mathrm{}_{}',
  label: (
    <CustomImage
      src={Group943}
      alt="Math mode with subscript"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 3,
  dataCy: 'mathrm_under',
}

export const mathrm_both = {
  handler: '\\mathrm{}_{}^{}',
  label: (
    <CustomImage
      src={Chem320}
      alt="Math mode with subscript and superscript"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 4,
  dataCy: 'mathrm_both',
}

export const mathrm_both_l = {
  handler: '{}_{}^{}\\mathrm{}',
  label: (
    <CustomImage
      src={Chem420}
      alt="Math mode with both subscript and superscript"
      width={30}
      height={28}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 4,
  dataCy: 'mathrm_both_l',
}

export const mathrm_braces = {
  handler: '\\left\\{\\mathrm {}\\right\\}',
  label: (
    <CustomImage
      src={Group1023}
      alt="Math mode with braces"
      width={34}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  numToMove: 2,
  dataCy: 'mathrm_braces',
}

export const mathrm_parentheses = {
  handler: '\\left(\\mathrm {}\\right)',
  label: <CustomImage src={Group944} alt="Math mode with parentheses" />,
  types: ['all'],
  command: 'write',
  numToMove: 2,
  dataCy: 'mathrm_parentheses',
}

export const mathrm_brackets = {
  handler: '\\left[\\mathrm {}\\right]',
  label: <CustomImage src={Group944v2} alt="Math mode with brackets" />,
  types: ['all'],
  command: 'write',
  numToMove: 2,
  dataCy: 'mathrm_brackets',
}

export const mathrm_mol = {
  handler: '\\mathrm{mol}',
  label: <CustomImage src={Chem820} alt="Math mode for mol" />,
  types: ['all'],
  command: 'write',
  dataCy: 'mathrm_mol',
}

export const overset_H = {
  handler: '\\overset{H}{}',
  label: <CustomImage src={Group1014} alt="overset H" width={14} height={31} />,
  types: ['all'],
  command: 'write',
  dataCy: 'overset_H',
}

export const log_under = {
  handler: '\\log_{}\\left({}\\right)', // handler: "\\iota"
  label: (
    <CustomImage
      src={Group8507}
      alt="Logarithm with subscript"
      width={34}
      height={26}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'log_under',
}

export const ln_left = {
  handler: '\\ln\\left({}\\right)',
  label: (
    <CustomImage
      src={Group3497}
      alt="Natural logarithm with parentheses"
      width={13}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'ln_left',
}

export const e_number = {
  handler: 'e',
  label: 'e',
  types: ['all'],
  command: 'cmd',
  dataCy: 'e_number',
}

export const nabla = {
  handler: '\\nabla{}',
  label: (
    <CustomImage src={Group8819} alt="Nabla operator" width={27} height={13} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'nabla',
}

export const nabla_times = {
  handler: '\\nabla\\times{}',
  label: (
    <CustomImage
      src={Group8820}
      alt="Nabla cross product"
      width={35}
      height={13}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'nabla_times',
}

export const delta_value = {
  handler: '\\triangle{}',
  label: (
    <CustomImage src={Group8821} alt="Delta symbol" width={27} height={13} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'delta_value',
}

export const delta_multiple = {
  handler: '\\triangle\\cdot{}',
  label: (
    <CustomImage
      src={Group8822}
      alt="Delta with dot product"
      width={34}
      height={24}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'delta_multiple',
}

export const integral_single = {
  handler: '\\indefinite',
  label: (
    <CustomImage
      src={Group3521}
      alt="Indefinite integral"
      width={10}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'integral_single',
}

// TODO: implement iint command in mathQuill
export const integral_double = {
  handler: '\\iint',
  label: (
    <CustomImage src={Group8823} alt="Double integral" width={24} height={28} />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'integral_double',
}

export const closed_contour = {
  handler: '\\oint',
  label: (
    <CustomImage
      src={Group8824}
      alt="Closed contour integral"
      width={20}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'closed_contour',
}

// TODO: implement surfintegral command in mathQuill
export const closed_surface = {
  handler: '\\surfintegral',
  label: (
    <CustomImage
      src={Group8825}
      alt="Surface integral"
      width={26}
      height={28}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'closed_surface',
}

export const prod_symbol = {
  handler: '\\prodSymbol',
  label: (
    <CustomImage
      src={Group8826}
      alt="Product symbol"
      width={12.5}
      height={20.8}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'prod_symbol',
}

export const prod = {
  handler: '\\prod',
  label: (
    <CustomImage
      src={Group8827}
      alt="Product symbol"
      width={10.8}
      height={42}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'prod',
}

export const prod_bottom = {
  handler: '\\prodLower',
  label: (
    <CustomImage
      src={Group8828}
      alt="Product symbol with lower limit"
      width={11}
      height={31}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'prod_bottom',
}

export const prod_upper = {
  handler: '\\prodUpper',
  label: (
    <CustomImage
      src={Group8829}
      alt="Product symbol with upper limit"
      width={11}
      height={31}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'prod_upper',
}

export const n_a = {
  handler: 'N_A',
  label: (
    <CustomImage
      src={Chem720}
      alt="Avogadro's number (N_A)"
      width={24}
      height={25}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'n_a',
}

export const k_b = {
  handler: 'K_B',
  label: (
    <CustomImage
      src={Chem620}
      alt="Boltzmann constant (K_B)"
      width={24}
      height={25}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'k_b',
}

export const bytes = {
  handler: '\\text{bytes}',
  label: (
    <CustomImage
      src={GroupBytes}
      alt="Bytes unit"
      width={42}
      height={24}
      style={{ marginLeft: 5 }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'bytes',
}

export const megabyte = {
  handler: '\\text{MB}',
  label: (
    <CustomImage
      src={GroupMegabyte}
      alt="Megabyte (MB) unit"
      width={26}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'megabyte',
}

export const gigabit = {
  handler: '\\text{GB}',
  label: (
    <CustomImage
      src={GroupGigabit}
      alt="Gigabit (GB) unit"
      width={26}
      height={22}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'gigabit',
}

export const mol = {
  handler: '\\text{mol}',
  label: (
    <CustomImage src={GroupMol} alt="Mole (mol) unit" width={34} height={24} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'mol',
}

export const gmol = {
  handler: '\\text{g/mol}',
  label: (
    <CustomImage
      src={GroupGramMol}
      alt="Gram per mole (g/mol) unit"
      width={42}
      height={21}
      style={{ marginLeft: 5 }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'gmol',
}

export const gmliter = {
  handler: '\\text{g/mL}',
  label: (
    <CustomImage
      src={GroupGramLiter}
      alt="Gram per milliliter (g/mL) unit"
      width={42}
      height={21}
      style={{ marginLeft: 5 }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'gmliter',
}

export const fog = {
  handler: '\\left(f\\circ g\\right)\\left(x\\right)',
  label: (
    <CustomImage src={Group9295} alt="fog symbol" width={32} height={18} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'fog',
}

export const percent = {
  handler: '\\%',
  label: (
    <CustomImage src={Group3528} alt="Percent symbol" width={15} height={14} />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'percent',
}

export const vdash = {
  handler: '\\vdash',
  label: (
    <CustomImage
      src={Group3171}
      alt="vdash symbol"
      width={16}
      height={16}
      style={{ transform: 'rotate(90deg)' }}
    />
  ),
  types: ['all'],
  command: 'cmd',
  dataCy: 'vdash',
}

export const circ = {
  handler: '^\\circ',
  label: (
    <CustomImage
      src={Group9296}
      alt="Circumflex (degree) symbol"
      width={8}
      height={8}
      style={{ marginBottom: 8 }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'circ',
}

export const vee = {
  handler: '\\vee',
  label: (
    <CustomImage
      src={GreaterThan}
      alt="Vee (disjunction) symbol"
      width={11}
      height={11}
      style={{ transform: 'rotate(90deg)' }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'vee',
}

export const barvee = {
  handler: '\\barvee',
  label: (
    <CustomImage
      src={Group9298}
      alt="Bar vee (logical disjunction with bar) symbol"
      width={11}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'barvee',
}

export const wedge = {
  handler: '\\wedge',
  label: (
    <CustomImage
      src={GreaterThan}
      alt="Wedge (logical conjunction) symbol"
      width={11}
      height={11}
      style={{ transform: 'rotate(270deg)' }}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'wedge',
}

export const barwedge = {
  handler: '\\barwedge',
  label: (
    <CustomImage
      src={Group9297}
      alt="Bar wedge (logical conjunction with bar) symbol"
      width={11}
      height={14}
    />
  ),
  types: ['all'],
  command: 'write',
  dataCy: 'barwedge',
}

/**
 * Constants related to external academic / non-academic feed types
 */
const CAASPP = 'CAASPP'
const iReady_Math = 'iReady_Math'
const iReady_ELA = 'iReady_ELA'
const NWEA = 'NWEA'
const GMAS_EOC = 'GMAS_EOC'
const GMAS_EOG = 'GMAS_EOG'
const ILEARN = 'ILEARN'
const STARR = 'STARR'
const TERM_GRADES = 'Term_Grades'
const FP_BAS = 'FP_BAS'
const FASTBRIDGE_EARLY_MATH_SCREENING = 'Fastbridge_Early_Math_Screening'
const FASTBRIDGE_EARLY_READING_ENGLISH_SCREENING =
  'Fastbridge_Early_Reading_English_Screening'
const FASTBRIDGE_AMATH = 'Fastbridge_aMath'
const FASTBRIDGE_AReading = 'Fastbridge_aReading'
const MCAS = 'MCAS'
const ACCESS = 'ACCESS'
const NHSAS = 'NHSAS'
const KSA = 'KSA'
const DRC_WIDA_ELL = 'DRC_WIDA_ELL'
const IAP_EOY = 'IAP_EOY'
const IAP_WINDOW = 'IAP_Window'
const MCAS_HISTORICAL = 'MCAS_Historical'
const ACCESS_V2 = 'ACCESS_v2'
const IAM = 'IAM'
const CLASS_REPORT = 'Class_Report'
const INTERIM_DATA = 'Interim_Data'
const IREAD3 = 'IREAD3'
const IXL = 'IXL'
const ON_COURSE_GRADES = 'On_Course_Grades'
const AP_MATSU = 'AP_MatSu'
const MASTERY_PREP = 'Mastery_Prep'
const ACCESS_NHDOE = 'ACCESS_NHDOE'
const IXL_ELA = 'IXL_ELA'
const IXL_MATH = 'IXL_Math'
const CORE_PHONICS = 'Core_Phonics'
const ACT_SHELBY = 'ACT_Shelby'

const ATTENDANCE = 'ATTENDANCE'

const NON_ACADEMIC_DATA_TYPES = {
  [ATTENDANCE]: 'Attendance',
}

const FASTBRIDGE_TEST_TYPES = [
  FASTBRIDGE_EARLY_MATH_SCREENING,
  FASTBRIDGE_EARLY_READING_ENGLISH_SCREENING,
  FASTBRIDGE_AMATH,
  FASTBRIDGE_AReading,
]

const FEED_TYPES_WITH_LEXILE_SCORE = [
  CAASPP,
  iReady_ELA,
  NWEA,
  GMAS_EOC,
  GMAS_EOG,
  ILEARN,
  STARR,
]

const FEED_TYPES_WITH_QUANTILE_SCORE = [CAASPP, iReady_Math, NWEA, ILEARN]

const FEED_TYPES_WITH_TEST_DATE_INPUT = [
  FP_BAS,
  TERM_GRADES,
  MCAS,
  ILEARN,
  ACCESS,
  KSA,
  DRC_WIDA_ELL,
  IAP_EOY,
  IAP_WINDOW,
  MCAS_HISTORICAL,
  ACCESS_V2,
  IAM,
  CLASS_REPORT,
  INTERIM_DATA,
  IREAD3,
  IXL,
  ON_COURSE_GRADES,
  AP_MATSU,
  MASTERY_PREP,
  ACCESS_NHDOE,
  IXL_ELA,
  IXL_MATH,
  CORE_PHONICS,
  ACT_SHELBY,
]

const FEED_TYPES_WITH_SFTP_ONLY = [NHSAS]

/**
 * Constants related to internal test types
 */
const TEST_TYPE_SURVEY = 'survey'

const TEST_TYPES = {
  ASSESSMENT: ['assessment'],
  COMMON: ['common assessment', 'school common assessment'],
  PRACTICE: ['practice', 'homework', 'quiz'],
  TESTLET: ['testlet'],
  SURVEY: [TEST_TYPE_SURVEY],
}

const ALL_TEST_TYPES_VALUES = Object.values(TEST_TYPES).flat()

const ALL_TEST_TYPES_KEYS = Object.keys(TEST_TYPES).map((item) =>
  item.toLowerCase()
)

const ALL_TEST_TYPES_KEYS_EXCLUDING_TESTLET = ALL_TEST_TYPES_KEYS.filter(
  (item) => !TEST_TYPES.TESTLET.includes(item)
)

const TEST_TYPES_VALUES_MAP = ALL_TEST_TYPES_VALUES.reduce((acc, curr) => {
  const _key = curr.split(' ').join('_').toUpperCase()
  acc[_key] = curr
  return acc
}, {})

const TEST_TYPE_LABELS = {
  'common assessment': 'District Common',
  'school common assessment': 'School Common',
  assessment: 'Class Assessment',
  practice: 'Practice Assessment',
  homework: 'Homework',
  quiz: 'Quiz',
  survey: 'Survey',
}

const DEFAULT_ADMIN_TEST_TYPE_MAP = {
  'district-admin': TEST_TYPES_VALUES_MAP.COMMON_ASSESSMENT,
  'school-admin': TEST_TYPES_VALUES_MAP.SCHOOL_COMMON_ASSESSMENT,
}

const DEFAULT_TEST_TYPES_BY_USER_ROLES = {
  ...DEFAULT_ADMIN_TEST_TYPE_MAP,
  teacher: TEST_TYPES_VALUES_MAP.ASSESSMENT,
}

const DEFAULT_ADMIN_TEST_TYPE_MAP_FILTER = {
  'district-admin': [TEST_TYPES_VALUES_MAP.COMMON_ASSESSMENT],
  'school-admin': [
    TEST_TYPES_VALUES_MAP.COMMON_ASSESSMENT,
    TEST_TYPES_VALUES_MAP.SCHOOL_COMMON_ASSESSMENT,
  ],
}

const PREMIUM_TEST_TYPES = [
  TEST_TYPES_VALUES_MAP.HOMEWORK,
  TEST_TYPES_VALUES_MAP.QUIZ,
]

module.exports = {
  TEST_TYPES,
  ALL_TEST_TYPES_VALUES,
  ALL_TEST_TYPES_KEYS,
  ALL_TEST_TYPES_KEYS_EXCLUDING_TESTLET,
  TEST_TYPES_VALUES_MAP,
  TEST_TYPE_LABELS,
  DEFAULT_ADMIN_TEST_TYPE_MAP,
  DEFAULT_ADMIN_TEST_TYPE_MAP_FILTER,
  DEFAULT_TEST_TYPES_BY_USER_ROLES,
  PREMIUM_TEST_TYPES,
  FP_BAS,
  ATTENDANCE,
  NON_ACADEMIC_DATA_TYPES,
  FASTBRIDGE_TEST_TYPES,
  TEST_TYPE_SURVEY,
  FEED_TYPES_WITH_TEST_DATE_INPUT,
  FEED_TYPES_WITH_LEXILE_SCORE,
  FEED_TYPES_WITH_QUANTILE_SCORE,
  FEED_TYPES_WITH_SFTP_ONLY,
}
